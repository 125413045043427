import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useLocation, useParams } from "react-router-dom";
import { Navbar } from "../../component/navbar";
import { Footer } from '../../section/footer';
import { KisahIntern } from "../../section/headline/data";
import { HeadlineFooter, HeadlineTag } from "../../section/headline/style";
import { timeSince } from "../../utils";
import { SectionContainer, SectionWrapper } from "../style";
import { JobDetailBody, JobDetailContainer, JobDetailContent, JobDetailHeader, JobDetailLeft, JobDetailMain } from "./style";

export const JobDetail = () => {
    const { uuid } = useParams();
    const [job, setJob] = useState({});
    const location = useLocation();

    useEffect(() => {
        fetchJobDetail();
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Kisah Intern" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchJobDetail = () => {
        const currJob = KisahIntern.find((item) => item.uuid === uuid);
        setJob(currJob);
    }
    return (
        <>
            <Navbar />
            <SectionWrapper style={{ paddingTop: "3rem", marginBottom: "0" }}>
                <SectionContainer>
                    <JobDetailHeader>
                        <JobDetailContainer>
                            <JobDetailLeft>
                                <HeadlineTag>
                                    <span>{job['category']}</span>
                                </HeadlineTag>
                                <JobDetailMain>
                                    <h1>{job.title}</h1>
                                    <p>{job.short}</p>
                                </JobDetailMain>
                                <HeadlineFooter style={{ marginTop: "2rem" }}>
                                    <span>
                                        <span>{job.created_at && timeSince(new Date(job.created_at))}</span>
                                    </span>
                                    <span>
                                        <span>{job.read} read</span>
                                    </span>
                                </HeadlineFooter>
                            </JobDetailLeft>
                        </JobDetailContainer>
                    </JobDetailHeader>
                    <JobDetailBody>
                        <img src={job.image_url} alt={`Header_${job.uuid}`} />
                        <JobDetailContent>
                            {job.content && parse(job.content)}
                        </JobDetailContent>
                    </JobDetailBody>
                </SectionContainer>
            </SectionWrapper>
            <Footer />
        </>
    )
}