import { createGlobalStyle } from "styled-components";
import AlteRegularWoff from '../assets/fonts/alte/AlteHaasGrotesk.woff';
import AlteRegularWoff2 from '../assets/fonts/alte/AlteHaasGrotesk.woff2';
import AlteBoldWoff from '../assets/fonts/alte/AlteHaasGrotesk_Bold.woff';
import AlteBoldWoff2 from '../assets/fonts/alte/AlteHaasGrotesk_Bold.woff2';
import MontagnaWoff from '../assets/fonts/montagna/MontagnaLTD.woff';
import MontagnaWoff2 from '../assets/fonts/montagna/MontagnaLTD.woff2';

export const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Montagna';
      src: url('${MontagnaWoff2}') format('woff2'),
          url('${MontagnaWoff}') format('woff');
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
      font-family: 'Alte';
      src: url('${AlteRegularWoff2}') format('woff2'),
          url('${AlteRegularWoff}') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Alte';
      src: url('${AlteBoldWoff2}') format('woff2'),
          url('${AlteBoldWoff}') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }


  * {
    font-family: 'Overpass', sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: -.02em;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.98);
    font-family: 'Space Grotesk', sans-serif;
  }

  h1, h2 {
    font-weight: 700;
  }

  ul {
    margin: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f3f5f6;
  }

  html {
    scroll-behavior: smooth
  }
`;