import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import { Navbar } from "../../component/navbar";
import { Footer } from "../../section/footer";
import { Headline } from "../../section/headline";
import { Jobs } from "../../section/jobs";

export const Home = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Homepage" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Navbar />
            <Headline />
            {/* <CVSection /> */}
            <Jobs />
            {/* <InstagramSection /> */}
            <Footer />
        </>
    )
}