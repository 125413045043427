import styled from "styled-components";
import bg from '../../assets/image/personality/bg.webp';

export const CommonAppsWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  max-width: 500px;
  position: relative;
  background-color: #eeeeee;
`;

export const AppsWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  max-width: 500px;
  position: relative;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const SectionWrapper = styled.section`
    padding: 2rem;
`;

export const SectionContainer = styled.div`
     & > h1 {
        font-size: 56px;
        line-height: 1.05;
     }

     & > p {
        font-size: 16px;
        margin: 8px 0 0;
     }
`;


export const QuestionContainer = styled.div`   
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    position: relative;
    
    & > h1 {
        font-size: 28px;
        line-height: 1;
        font-family: 'Montagna', sans-serif;
        margin: 8px 0 0;
    }

    & > span {
        font-size: 18px;
        font-weight: 700;
    }
`;

export const ArrowBackWrapper = styled.div`
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-bottom: 1.5rem;
    gap: 6px;
`;

export const BottomSheetHeader = styled.div`
    display: flex;
    gap: 12px;

    & > p {
        font-weight: 500;
        & > span {
            font-weight: 700;
            color: orange;
        }

        color: #d9d9d9;
    }
`;


export const ButtonContainer = styled.div`
    position: absolute;
    padding: 1rem 2rem;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
`;

export const ButtonFlex = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1rem;
`;

export const QuestionButton = styled.button`
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #000;
    border-radius: 12px;
    box-shadow: 0 0 #0000 ,0 0 #0000, 4px 4px 0 0 #000!important;
    padding: 10px 20px;
    color: #000;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-weight: 500;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
`;

export const BottomSheet = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    min-height: 300px;
`;

export const BottomSheetContainer = styled.div`
    padding: 3rem 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > h1 {
        font-size: 32px;
        line-height: 1.1;
        margin: 1rem 0;
    }

    & > p  {
        font-size: 17px;
    }

    & > input {
        width: 100%;
        margin: 1rem 0 0;
        padding: 10px 12px;
        border-radius: 8px;
        border: 1px solid #ddd;
        outline: none;
        font-size: 16px;
    }

    & > span {
        margin-top: 4px;
        & > a {
            color: inherit;
        }
    }
`;

export const BottomSheetContainerQuestion = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;

    & > h1 {
        font-size: 28px;
        margin: 12px 0;
        font-family: 'Albert Sans', sans-serif;
    }
`;

export const StartedButton = styled.button`
    background-color: #000;
    height: 50px;
    border-radius: 8px;
    border: 0;
    outline: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 17px;
    margin-top: 4rem;
    margin-bottom: 10px;
`;