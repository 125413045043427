import React from "react";
import * as Md from 'react-icons/md';
import { ButtonNavbar, NavbarContainer, NavbarFlex, NavbarMenu, NavbarMobileMenu, NavbarWrapper } from "./style";

export const Navbar = () => {
    return (
        <NavbarWrapper>
            <NavbarContainer>
                <NavbarFlex>
                    <h1 onClick={() => window.location = "/"}>intrn</h1>
                    {/* <img src={logo} alt="logo" /> */}
                    <NavbarMobileMenu>
                        <ButtonNavbar href="/personality">
                            Tes Personality
                            <Md.MdOutlineArrowOutward size={15} />
                        </ButtonNavbar>
                    </NavbarMobileMenu>
                    <NavbarMenu>
                        <ul>
                            <li>
                                <a href="#intern">
                                    <span>#MagangHariIni</span>
                                </a>
                            </li>
                            <li>
                                <a href="#story">
                                    <span>Kisah Intern</span>
                                </a>
                            </li>
                            <li>
                                <ButtonNavbar href="/personality" style={{ marginLeft: "8px" }}>
                                    Tes Personality
                                    <Md.MdOutlineArrowOutward size={15} />
                                </ButtonNavbar>
                            </li>
                        </ul>
                    </NavbarMenu>
                </NavbarFlex>
            </NavbarContainer>
        </NavbarWrapper>
    )
}