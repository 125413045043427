import styled from "styled-components";

export const NavbarWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 1px 0 rgba(5, 122, 255, 0.06);
    z-index: 999;
`;

export const NavbarContainer = styled.div`
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 10px 1.25rem;

    @media screen and (min-width: 1024px) {
        padding: 12px;
    }
`;

export const NavbarFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > img {
        width: 60px;
    }

    & > h1 {
        font-size: 24px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }


    @media screen and (min-width: 1024px) {
        & > img {
            width: 80px;
        }
    }
`;

export const NavbarMobileMenu = styled.div`
    display: block;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

export const ButtonNavbar = styled.a`
    background-color: #fdd318;
    text-decoration: none;
    outline: none;
    border: 0;
    height: 32px;
    padding: 0 8px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-family: 'Montagna', sans-serif;
    font-size: 14px;
    gap: 4px;
    color: #000;

    &:visited {
        color: inherit;
    }
`;

export const NavbarMenu = styled.nav`
    display: none;
    gap: 17px;

    & > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        align-items: center;

        & > li {
            & > a {
                text-decoration: none;
                padding-inline-end: .5rem;
                padding-inline-start: .5rem;

                & > span {
                    color: #333;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        display: flex;
    }
`;