export const profiles = {
    "ESTP": {
        "name": "Pengusaha",
        "description": "ESTP itu orangnya rame, energik, dan suka bertindak cepat. Mereka suka jadi pusat perhatian dan jago mikir cepat.",
        "strengths": ["Karismatik", "Energik", "Praktis", "Observan"],
        "weaknesses": ["Impulsif", "Berisiko Tinggi", "Tidak Sabar", "Kurang Sensitif"],
        "suitable_jobs": ["Entrepreneur", "Sales Manager", "Marketing Executive", "Emergency Medical Technician", "Stockbroker"],
        "famous_persons": ["Ernest Hemingway", "Donald Trump", "Madonna", "Bruce Willis"],
        "compatible_with": ["ISTP", "ISFP", "ENTP", "ESTP"],
        "not_compatible_with": ["INFJ", "INFP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219429/personality/estp_cxl8ap.png",
        "color": "#fdd318"
    },
    "INFJ": {
        "name": "Advokat",
        "description": "INFJ adalah orang yang dalam dan penuh perasaan. Mereka cenderung memahami perasaan orang lain dan punya visi jangka panjang.",
        "strengths": ["Empatik", "Visioner", "Penuh Perhatian", "Kreatif"],
        "weaknesses": ["Terlalu Idealistik", "Terlalu Sensitif", "Terputus dari Realitas", "Mudah Tertekan"],
        "suitable_jobs": ["Counselor", "Psychologist", "Writer", "Artist", "Human Resources Manager"],
        "famous_persons": ["Mahatma Gandhi", "Carl Jung", "Nelson Mandela", "Mother Teresa"],
        "compatible_with": ["ENFP", "ENFJ", "INTJ", "INFJ"],
        "not_compatible_with": ["ESTP", "ENTP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219430/personality/infj_vxnhvt.png",
        "color": "#54b08b"
    },
    "ENTP": {
        "name": "Wirausahawan",
        "description": "ENTP itu orangnya cerdas, inovatif, dan suka tantangan. Mereka senang berdiskusi dan mencari solusi kreatif untuk masalah.",
        "strengths": ["Kreatif", "Cerdas", "Adaptif", "Berani"],
        "weaknesses": ["Terlalu Argumentatif", "Tidak Sabar", "Kurang Perhatian pada Detail", "Terlalu Sering Mengubah Rencana"],
        "suitable_jobs": ["Lawyer", "Journalist", "Consultant", "Entrepreneur", "Marketing Director"],
        "famous_persons": ["Thomas Edison", "John Stewart", "Sacha Baron Cohen", "Céline Dion"],
        "compatible_with": ["INTP", "ENFP", "ENTJ", "ENTP"],
        "not_compatible_with": ["ISFJ", "ISTJ"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219429/personality/entp_ujqvvz.png",
        "color": "#d1a1b8"
    },
    "ISFJ": {
        "name": "Pembela",
        "description": "ISFJ adalah orang yang sangat perhatian, praktis, dan setia. Mereka suka membantu orang lain dan menjaga keharmonisan.",
        "strengths": ["Perhatian", "Setia", "Praktis", "Teratur"],
        "weaknesses": ["Terlalu Mengalah", "Sulit Menolak", "Terlalu Terikat pada Tradisi", "Mudah Tersinggung"],
        "suitable_jobs": ["Nurse", "Teacher", "Administrative Assistant", "Social Worker", "Accountant"],
        "famous_persons": ["Princess Diana", "Mother Teresa", "George H.W. Bush", "David Beckham"],
        "compatible_with": ["ESFJ", "ESTJ", "ISTJ", "ISFJ"],
        "not_compatible_with": ["ENTP", "ESTP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219431/personality/isfj_unj9n1.png",
        "color": "#50a9aa"
    },
    "INTJ": {
        "name": "Arsitek",
        "description": "INTJ adalah orang yang strategis, analitis, dan ambisius. Mereka suka merencanakan jauh ke depan dan bekerja secara independen.",
        "strengths": ["Strategis", "Analitis", "Mandiri", "Ambisius"],
        "weaknesses": ["Terlalu Kritis", "Kurang Perhatian pada Perasaan", "Terlalu Menuntut", "Kaku"],
        "suitable_jobs": ["Scientist", "Engineer", "Architect", "Consultant", "Project Manager"],
        "famous_persons": ["Elon Musk", "Hillary Clinton", "Mark Zuckerberg", "Isaac Newton"],
        "compatible_with": ["ENTJ", "ENFP", "INTP", "INTJ"],
        "not_compatible_with": ["ESFP", "ISFP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219431/personality/intj_gklvw4.png",
        "color": "#d1a1b8"
    },
    "ESFP": {
        "name": "Penghibur",
        "description": "ESFP adalah orang yang ceria, spontan, dan sangat sosial. Mereka suka berada di tengah keramaian dan menikmati setiap momen.",
        "strengths": ["Ceria", "Spontan", "Sosial", "Fleksibel"],
        "weaknesses": ["Kurang Perhatian pada Detail", "Cenderung Menghindari Masalah", "Terlalu Bergantung pada Kesenangan", "Impulsif"],
        "suitable_jobs": ["Event Planner", "Actor", "Travel Guide", "Sales Representative", "Restaurant Manager"],
        "famous_persons": ["Miley Cyrus", "Sofia Vergara", "Will Smith", "Justin Bieber"],
        "compatible_with": ["ISFP", "ENFP", "ESTP", "ESFP"],
        "not_compatible_with": ["INTJ", "INFJ"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219430/personality/esfp_fdoa1l.png",
        "color": "#fdd318"
    },
    "INFP": {
        "name": "Mediator",
        "description": "INFP adalah orang yang idealis, penuh perasaan, dan kreatif. Mereka sering kali mencari makna yang lebih dalam dalam hidup.",
        "strengths": ["Idealistik", "Kreatif", "Penuh Perasaan", "Empatik"],
        "weaknesses": ["Terlalu Sensitif", "Terlalu Idealistik", "Mudah Tersinggung", "Sulit Menghadapi Kritik"],
        "suitable_jobs": ["Author", "Counselor", "Artist", "Musician", "Librarian"],
        "famous_persons": ["J.R.R. Tolkien", "William Shakespeare", "A. A. Milne", "Princess Diana"],
        "compatible_with": ["ENFP", "INFJ", "ISFP", "INFP"],
        "not_compatible_with": ["ESTJ", "ENTJ"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219430/personality/infp_xx4fgi.png",
        "color": "#54b08b"
    },
    "ISTP": {
        "name": "Ahli",
        "description": "ISTP adalah orang yang praktis, independen, dan suka memecahkan masalah dengan cara yang hands-on. Mereka sering kali tenang dan terampil.",
        "strengths": ["Praktis", "Mandiri", "Tenang", "Terampil"],
        "weaknesses": ["Kurang Ekspresif", "Cenderung Tertutup", "Tidak Sabar", "Kurang Perhatian pada Perasaan Orang Lain"],
        "suitable_jobs": ["Mechanic", "Engineer", "Surgeon", "Pilot", "Technician"],
        "famous_persons": ["Steve Jobs", "Clint Eastwood", "Bear Grylls", "Michael Jordan"],
        "compatible_with": ["ESTP", "ISFP", "INTP", "ISTP"],
        "not_compatible_with": ["ENFJ", "ESFJ"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219431/personality/istp_om2enf.png",
        "color": "#fdd318"
    },
    "ESFJ": {
        "name": "Konsul",
        "description": "ESFJ adalah orang yang ramah, terorganisir, dan sangat peduli dengan orang lain. Mereka suka menjaga hubungan baik dan membantu orang di sekitar mereka.",
        "strengths": ["Ramah", "Terorganisir", "Peduli", "Bisa Diandalkan"],
        "weaknesses": ["Terlalu Bergantung pada Pendapat Orang Lain", "Sulit Menolak", "Terlalu Mengandalkan Rutinitas", "Kurang Fleksibel"],
        "suitable_jobs": ["Teacher", "Nurse", "Social Worker", "Event Planner", "Customer Service Representative"],
        "famous_persons": ["Jennifer Garner", "Taylor Swift", "Harry Styles", "Sofia Vergara"],
        "compatible_with": ["ISFJ", "ENFJ", "ESFP", "ESFJ"],
        "not_compatible_with": ["INTJ", "ISTP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219430/personality/esfj_b2u06g.png",
        "color": "#50a9aa"
    },
    "INTP": {
        "name": "Pemikir",
        "description": "INTP adalah orang yang analitis, rasional, dan suka mengeksplorasi ide-ide baru. Mereka cenderung tertarik pada teori dan konsep yang kompleks.",
        "strengths": ["Analitis", "Rasional", "Kreatif", "Mandiri"],
        "weaknesses": ["Kurang Perhatian pada Detail", "Terlalu Kritis", "Kurang Ekspresif", "Terlalu Terpusat pada Pikiran"],
        "suitable_jobs": ["Scientist", "Philosopher", "Software Developer", "Mathematician", "Researcher"],
        "famous_persons": ["Albert Einstein", "Stephen Hawking", "Marie Curie", "Bill Gates"],
        "compatible_with": ["ENTP", "INTJ", "INFJ", "INTP"],
        "not_compatible_with": ["ESFJ", "ISFJ"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219430/personality/intp_lpobma.png",
        "color": "#d1a1b8"
    },
    "ISFP": {
        "name": "Petualang",
        "description": "ISFP adalah orang yang lembut, artistik, dan suka menikmati keindahan hidup. Mereka cenderung mengikuti perasaan dan memilih gaya hidup yang tenang.",
        "strengths": ["Penuh Perasaan", "Kreatif", "Sederhana", "Suka Menolong"],
        "weaknesses": ["Terlalu Sensitif", "Kurang Terstruktur", "Kurang Berorientasi pada Tujuan", "Sulit Menyatakan Pendapat"],
        "suitable_jobs": ["Artist", "Designer", "Photographer", "Chef", "Counselor"],
        "famous_persons": ["Marilyn Monroe", "David Bowie", "Kurt Cobain", "Audrey Hepburn"],
        "compatible_with": ["ESFP", "INFP", "ISFJ", "ISFP"],
        "not_compatible_with": ["ENTJ", "ESTJ"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1721487906/personality/isfp_pqp8zp.svg",
        "color": "#fdd318"
    },
    "ENFP": {
        "name": "Kampiun",
        "description": "ENFP adalah orang yang bersemangat, kreatif, dan penuh ide. Mereka suka mengeksplorasi kemungkinan baru dan berhubungan dengan orang lain.",
        "strengths": ["Kreatif", "Penuh Semangat", "Sosial", "Optimis"],
        "weaknesses": ["Terlalu Idealis", "Terlalu Emosional", "Mudah Bosan", "Kurang Fokus pada Detail"],
        "suitable_jobs": ["Writer", "Actor", "Counselor", "Marketing Specialist", "Event Planner"],
        "famous_persons": ["Robin Williams", "Walt Disney", "Oprah Winfrey", "Ellen DeGeneres"],
        "compatible_with": ["INFJ", "INTJ", "ENFJ", "ENFP"],
        "not_compatible_with": ["ISTJ", "ESTJ"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219429/personality/enfp_hwvt0i.png",
        "color": "#54b08b"
    },
    "ENTJ": {
        "name": "Komandan",
        "description": "ENTJ adalah orang yang percaya diri, terorganisir, dan memiliki kemampuan kepemimpinan yang kuat. Mereka suka merencanakan dan mengarahkan proyek besar.",
        "strengths": ["Kepemimpinan", "Terorganisir", "Analitis", "Ambisius"],
        "weaknesses": ["Terlalu Dominan", "Kurang Empati", "Terlalu Menuntut", "Kurang Fleksibel"],
        "suitable_jobs": ["CEO", "Manager", "Lawyer", "Project Manager", "Consultant"],
        "famous_persons": ["Margaret Thatcher", "Franklin D. Roosevelt", "Steve Jobs", "Hillary Clinton"],
        "compatible_with": ["INTJ", "ENFP", "ENTP", "ENTJ"],
        "not_compatible_with": ["ISFP", "INFP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219429/personality/entj_bqoghc.png",
        "color": "#d1a1b8"
    },
    "ISTJ": {
        "name": "Ahli Logistik",
        "description": "ISTJ adalah orang yang sangat terorganisir, bertanggung jawab, dan praktis. Mereka suka bekerja dengan cara yang sistematis dan dapat diandalkan.",
        "strengths": ["Terorganisir", "Praktis", "Bisa Diandalkan", "Detail-Oriented"],
        "weaknesses": ["Kaku", "Kurang Fleksibel", "Terlalu Fokus pada Aturan", "Kurang Ekspresif"],
        "suitable_jobs": ["Accountant", "Police Officer", "Engineer", "Administrator", "Project Manager"],
        "famous_persons": ["George Washington", "Angela Merkel", "John D. Rockefeller", "Laura Bush"],
        "compatible_with": ["ESTJ", "ISFJ", "INTJ", "ISTJ"],
        "not_compatible_with": ["ENFP", "ESFP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219431/personality/istj_n8ttjy.png",
        "color": "#50a9aa"
    },
    "ESTJ": {
        "name": "Eksekutif",
        "description": "ESTJ adalah orang yang tegas, terorganisir, dan suka memimpin. Mereka sangat fokus pada hasil dan memiliki cara yang jelas dalam mencapai tujuan.",
        "strengths": ["Kepemimpinan", "Terorganisir", "Praktis", "Berorientasi pada Hasil"],
        "weaknesses": ["Terlalu Dominan", "Kurang Empati", "Kaku", "Terlalu Menuntut"],
        "suitable_jobs": ["Manager", "Judge", "Police Officer", "Sales Executive", "Military Officer"],
        "famous_persons": ["Margaret Thatcher", "Franklin D. Roosevelt", "Colin Powell", "Eleanor Roosevelt"],
        "compatible_with": ["ISTJ", "ISFJ", "ENTJ", "ESTJ"],
        "not_compatible_with": ["INFP", "ISFP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219430/personality/estj_igo9us.png",
        "color": "#50a9aa"
    },
    "ENFJ": {
        "name": "Protagonis",
        "description": "ENFJ adalah orang yang karismatik, inspiratif, dan peduli. Mereka pandai memotivasi orang lain dan sering menjadi pemimpin alami.",
        "strengths": ["Karismatik", "Inspiratif", "Sosial", "Empatik"],
        "weaknesses": ["Terlalu Mengandalkan Perasaan", "Sulit Menolak", "Cenderung Mengabaikan Kebutuhan Sendiri", "Kurang Fokus pada Detail"],
        "suitable_jobs": ["Teacher", "Counselor", "Public Relations Manager", "Event Coordinator", "Human Resources Manager"],
        "famous_persons": ["barack_obama", "morgan_freeman", "oprah_winfrey", "julius_caesar"],
        "compatible_with": ["INFJ", "ENFP", "ESFJ", "ENFJ"],
        "not_compatible_with": ["INTP", "ISTP"],
        "image_url": "https://res.cloudinary.com/internkuy/image/upload/v1719219429/personality/enfj_tv7fe7.png",
        "color": "#54b08b"
    }
};

export const ProfilesWithCompanies = {
    "ESTP": {
        "name": "Pengusaha",
        "description": "ESTP itu orangnya rame, energik, dan suka bertindak cepat. Mereka suka jadi pusat perhatian dan jago mikir cepat.",
        "suitable_companies": ["Tokopedia", "Bukalapak", "Traveloka"]
    },
    "INFJ": {
        "name": "Advokat",
        "description": "INFJ adalah orang yang dalam dan penuh perasaan. Mereka cenderung memahami perasaan orang lain dan punya visi jangka panjang.",
        "suitable_companies": ["RuangGuru", "Kopernik", "Kitabisa"]
    },
    "ENTP": {
        "name": "Wirausahawan",
        "description": "ENTP itu orangnya cerdas, inovatif, dan suka tantangan. Mereka senang berdiskusi dan mencari solusi kreatif untuk masalah.",
        "suitable_companies": ["Gojek", "East Ventures", "Kredivo"]
    },
    "ISFJ": {
        "name": "Pembela",
        "description": "ISFJ adalah orang yang sangat perhatian, praktis, dan setia. Mereka suka membantu orang lain dan menjaga keharmonisan.",
        "suitable_companies": ["Siloam Hospitals", "Unilever Indonesia", "Garuda Indonesia"]
    },
    "INTJ": {
        "name": "Arsitek",
        "description": "INTJ adalah orang yang strategis, analitis, dan ambisius. Mereka suka merencanakan jauh ke depan dan bekerja secara independen.",
        "suitable_companies": ["Telkom Indonesia", "Bank Mandiri", "Pertamina"]
    },
    "ESFP": {
        "name": "Penghibur",
        "description": "ESFP adalah orang yang ceria, spontan, dan sangat sosial. Mereka suka berada di tengah keramaian dan menikmati setiap momen.",
        "suitable_companies": ["NET TV", "Transmedia", "Dyandra Promosindo"]
    },
    "INFP": {
        "name": "Mediator",
        "description": "INFP adalah orang yang idealis, penuh perasaan, dan kreatif. Mereka sering kali mencari makna yang lebih dalam dalam hidup.",
        "suitable_companies": ["Gramedia", "Mizan", "Kompas"]
    },
    "ISTP": {
        "name": "Ahli",
        "description": "ISTP adalah orang yang praktis, independen, dan suka memecahkan masalah dengan cara yang hands-on. Mereka sering kali tenang dan terampil.",
        "suitable_companies": ["Astra International", "Bukit Asam", "Adaro Energy"]
    },
    "ESFJ": {
        "name": "Konsul",
        "description": "ESFJ adalah orang yang ramah, terorganisir, dan sangat peduli dengan orang lain. Mereka suka menjaga hubungan baik dan membantu orang di sekitar mereka.",
        "suitable_companies": ["Sari Roti", "Nestlé Indonesia", "Danone Indonesia"]
    },
    "INTP": {
        "name": "Pemikir",
        "description": "INTP adalah orang yang analitis, rasional, dan suka mengeksplorasi ide-ide baru. Mereka cenderung tertarik pada teori dan konsep yang kompleks.",
        "suitable_companies": ["Bukalapak", "Indosat Ooredoo", "Traveloka"]
    },
    "ISFP": {
        "name": "Petualang",
        "description": "ISFP adalah orang yang lembut, artistik, dan suka menikmati keindahan hidup. Mereka cenderung mengikuti perasaan dan memilih gaya hidup yang tenang.",
        "suitable_companies": ["Tiket.com", "Sociolla", "Erigo"]
    },
    "ENFP": {
        "name": "Kampiun",
        "description": "ENFP adalah orang yang bersemangat, kreatif, dan penuh ide. Mereka suka mengeksplorasi kemungkinan baru dan berhubungan dengan orang lain.",
        "suitable_companies": ["Gojek", "Tokopedia", "Zenius Education"]
    },
    "ENTJ": {
        "name": "Komandan",
        "description": "ENTJ adalah orang yang percaya diri, terorganisir, dan memiliki kemampuan kepemimpinan yang kuat. Mereka suka merencanakan dan mengarahkan proyek besar.",
        "suitable_companies": ["Bank Central Asia (BCA)", "Unilever Indonesia", "Pertamina"]
    },
    "ISTJ": {
        "name": "Ahli Logistik",
        "description": "ISTJ adalah orang yang sangat terorganisir, bertanggung jawab, dan praktis. Mereka suka bekerja dengan cara yang sistematis dan dapat diandalkan.",
        "suitable_companies": ["Bank Negara Indonesia (BNI)", "Astra International", "Telkom Indonesia"]
    },
    "ESTJ": {
        "name": "Eksekutif",
        "description": "ESTJ adalah orang yang tegas, terorganisir, dan suka memimpin. Mereka sangat fokus pada hasil dan memiliki cara yang jelas dalam mencapai tujuan.",
        "suitable_companies": ["Bank Mandiri", "PLN", "Garuda Indonesia"]
    },
    "ENFJ": {
        "name": "Protagonis",
        "description": "ENFJ adalah orang yang karismatik, inspiratif, dan peduli. Mereka pandai memotivasi orang lain dan sering menjadi pemimpin alami.",
        "suitable_companies": ["RuangGuru", "Kitabisa", "Unilever Indonesia"]
    }
};

export const ProfilesWithSuggestions = {
    "INTJ": {
        "when_under_pressure_on_work": "Bikin rencana detail dan prioritas tugas. Ambil waktu sejenak buat rileks, misalnya jalan-jalan sebentar atau dengerin musik. Jangan lupa komunikasikan sama tim kalo butuh bantuan, biar semuanya bisa berjalan lancar.",
        "when_bored_on_work": "Cari tantangan baru atau proyek yang lebih kompleks buat bikin otak tetap aktif. Bisa juga baca artikel atau buku yang menginspirasi. Jangan ragu untuk usulin ide-ide kreatif ke atasan atau tim.",
        "kind_of_music_to_hear_when_tired": "Instrumental atau musik klasik buat bantu fokus lagi. Pilihan lain bisa juga musik ambient yang tenang.",
        "comfort_food_to_eat": "Cokelat hitam atau kopi berkualitas buat ningkatin mood dan energi. Camilan sehat seperti kacang-kacangan juga bisa jadi pilihan.",
        "what_to_do_to_ask_salary_increment_to_boss": "Siapkan data performa dan pencapaian kamu. Ajukan proposal dengan argumen yang logis dan terstruktur. Jelaskan bagaimana kontribusi kamu udah bantu perusahaan, dan jangan lupa tunjukkan kesiapan untuk tanggung jawab lebih besar."
    },
    "INFJ": {
        "when_under_pressure_on_work": "Ambil napas dalam-dalam dan coba meditasi sebentar buat tenangin pikiran. Atur waktu buat istirahat sejenak dan refleksi diri. Jangan sungkan buat curhat sama teman dekat atau mentor di kantor.",
        "when_bored_on_work": "Cari proyek yang bisa memicu sisi kreatif kamu. Terlibat dalam kegiatan sosial kantor atau ikut proyek volunteer bisa bikin kamu lebih semangat. Usulin ide-ide baru ke tim biar suasana kerja lebih dinamis.",
        "kind_of_music_to_hear_when_tired": "Musik akustik atau indie yang tenang dan menenangkan. Bisa juga dengerin lagu-lagu favorit yang punya makna khusus buat kamu.",
        "comfort_food_to_eat": "Teh herbal hangat dan camilan sehat seperti buah-buahan atau yoghurt. Dark chocolate juga bisa bantu mood jadi lebih baik.",
        "what_to_do_to_ask_salary_increment_to_boss": "Persiapkan diri dengan catatan kontribusi dan pencapaian kamu. Bicarakan dengan jujur dan terbuka tentang keinginan kamu buat gaji yang lebih baik. Jelaskan bagaimana kamu bisa terus berkontribusi untuk perusahaan ke depannya."
    },
    "ENTP": {
        "when_under_pressure_on_work": "Cari cara baru dan kreatif buat selesaikan masalah. Jangan ragu buat diskusi sama tim atau atasan buat cari solusi terbaik. Istirahat sebentar buat refreshing pikiran juga bisa bantu banget.",
        "when_bored_on_work": "Ambil proyek atau tugas yang beda dari biasanya buat stimulasi otak. Cari tantangan baru atau coba teknik baru dalam pekerjaan kamu. Jangan lupa buat tetap interaksi sama rekan kerja biar nggak jenuh.",
        "kind_of_music_to_hear_when_tired": "Musik dengan tempo cepat atau musik rock yang penuh energi buat boost semangat lagi.",
        "comfort_food_to_eat": "Burger atau pizza buat ngenyangin perut dan bikin mood lebih baik. Minuman soda atau kopi dingin juga bisa jadi pilihan.",
        "what_to_do_to_ask_salary_increment_to_boss": "Tunjukkan pencapaian kamu dengan data yang konkret dan jelas. Jelaskan ide-ide kamu untuk proyek ke depan dan bagaimana kamu bisa terus berinovasi. Jadilah percaya diri dan sampaikan dengan antusias."
    },
    "ISFJ": {
        "when_under_pressure_on_work": "Ambil napas dalam-dalam dan coba buat rileks sebentar. Prioritaskan tugas yang paling penting dan selesaikan satu per satu. Jangan sungkan minta bantuan atau saran dari rekan kerja atau atasan.",
        "when_bored_on_work": "Cari tugas yang bisa membuat kamu merasa lebih bermakna. Terlibat dalam proyek yang membantu orang lain atau yang kamu pedulikan. Jangan ragu buat mengusulkan perubahan kecil yang bisa meningkatkan efisiensi kerja.",
        "kind_of_music_to_hear_when_tired": "Musik pop yang ringan atau lagu-lagu lama yang membawa nostalgia dan kenyamanan.",
        "comfort_food_to_eat": "Kue atau roti yang manis dan menenangkan. Teh hangat dengan madu juga bisa membantu kamu merasa lebih baik.",
        "what_to_do_to_ask_salary_increment_to_boss": "Persiapkan diri dengan catatan kontribusi dan bagaimana kamu telah membantu tim dan perusahaan. Sampaikan dengan rendah hati dan hormat, serta tunjukkan keinginan kamu untuk terus memberikan yang terbaik."
    },
    "ESFJ": {
        "when_under_pressure_on_work": "Ambil waktu buat bicara dengan rekan kerja atau atasan tentang apa yang bikin kamu stres. Kerja sama tim bisa bantu kamu merasa lebih ringan. Jangan lupa buat istirahat sejenak dan jaga kesehatan.",
        "when_bored_on_work": "Cari cara buat membuat suasana kerja lebih menyenangkan. Buat rencana acara sosial atau kegiatan team-building. Bisa juga coba variasi dalam tugas harian biar nggak monoton.",
        "kind_of_music_to_hear_when_tired": "Musik pop atau lagu-lagu hits yang ceria dan menghibur.",
        "comfort_food_to_eat": "Ice cream atau milkshake buat bikin mood lebih baik. Salad buah segar juga bisa jadi pilihan sehat yang menyegarkan.",
        "what_to_do_to_ask_salary_increment_to_boss": "Tunjukkan bagaimana kontribusi kamu udah berpengaruh positif buat tim dan perusahaan. Sampaikan keinginan kamu dengan sopan dan jelas, serta jelaskan bagaimana kamu siap untuk tanggung jawab yang lebih besar."
    },
    "ISTP": {
        "when_under_pressure_on_work": "Cari cara praktis dan efektif buat selesaikan tugas. Break sebentar buat ngelakuin aktivitas fisik bisa bantu kamu fokus lagi. Jangan ragu buat cari informasi tambahan atau referensi buat solusi yang lebih baik.",
        "when_bored_on_work": "Coba proyek atau tugas baru yang lebih menantang. Libatkan diri dalam problem-solving atau mekanik yang membutuhkan keahlian teknis kamu. Ganti suasana kerja atau pindah tempat kerja sementara bisa bantu hilangin rasa bosan.",
        "kind_of_music_to_hear_when_tired": "Musik elektronik atau techno yang punya beat kuat buat bantu konsentrasi.",
        "comfort_food_to_eat": "Snack gurih seperti keripik atau kacang. Minuman energi atau kopi juga bisa bantu jaga stamina.",
        "what_to_do_to_ask_salary_increment_to_boss": "Bawa bukti nyata dari hasil kerja dan inovasi kamu. Sampaikan dengan tegas dan langsung, jelaskan bagaimana kamu bisa terus berkontribusi dengan lebih baik. Tunjukkan kesiapan kamu buat belajar dan berkembang."
    },
    "ENFP": {
        "when_under_pressure_on_work": "Ambil waktu buat kreatif brainstorming dan cari ide-ide baru. Jangan ragu buat diskusi sama teman atau rekan kerja buat dapat perspektif baru. Ambil waktu sejenak buat istirahat dan recharge energi kamu.",
        "when_bored_on_work": "Cari proyek yang bisa bikin kamu lebih antusias dan termotivasi. Coba kegiatan baru atau usulkan ide-ide kreatif buat suasana kerja lebih hidup. Jangan lupa buat tetap terhubung dengan orang-orang di sekitar kamu.",
        "kind_of_music_to_hear_when_tired": "Musik upbeat atau lagu-lagu dengan lirik inspiratif yang bisa membangkitkan semangat.",
        "comfort_food_to_eat": "Smoothie buah segar atau cokelat panas buat nyenengin hati. Salad buah atau yogurt juga bisa jadi pilihan.",
        "what_to_do_to_ask_salary_increment_to_boss": "Siapkan presentasi yang menarik tentang kontribusi dan ide-ide kamu. Jelaskan dengan penuh semangat dan antusiasme bagaimana kamu bisa terus berinovasi. Tunjukkan komitmen kamu untuk terus belajar dan berkembang."
    },
    "INFP": {
        "when_under_pressure_on_work": "Ambil waktu buat refleksi dan meditasi sebentar buat tenangin pikiran. Jangan ragu buat curhat sama teman dekat atau mentor di kantor. Cari cara buat mengingatkan diri tentang tujuan dan makna dari pekerjaan kamu.",
        "when_bored_on_work": "Cari proyek yang bisa memberikan kamu rasa lebih bermakna dan terinspirasi. Libatkan diri dalam kegiatan kreatif atau sosial yang kamu pedulikan. Jangan ragu buat eksplorasi ide-ide baru yang bisa membawa perubahan positif.",
        "kind_of_music_to_hear_when_tired": "Musik akustik atau lagu-lagu indie yang tenang dan menenangkan.",
        "comfort_food_to_eat": "Teh herbal hangat atau camilan sehat seperti buah-buahan kering. Dark chocolate juga bisa bantu mood jadi lebih baik.",
        "what_to_do_to_ask_salary_increment_to_boss": "Siapkan diri dengan catatan kontribusi dan pencapaian kamu. Bicarakan dengan jujur dan terbuka tentang keinginan kamu buat gaji yang lebih baik. Jelaskan bagaimana kamu bisa terus berkontribusi untuk perusahaan ke depannya."
    },
    "ESFP": {
        "when_under_pressure_on_work": "Ambil waktu buat rileks dan cari hiburan sebentar. Bicara sama teman atau rekan kerja bisa bantu ngurangin stres. Jangan lupa buat tetap positif dan lihat sisi menyenangkan dari situasi.",
        "when_bored_on_work": "Cari cara buat bikin suasana kerja lebih hidup dan menyenangkan. Libatkan diri dalam acara sosial atau kegiatan team-building. Coba variasi dalam tugas harian biar nggak monoton.",
        "kind_of_music_to_hear_when_tired": "Musik pop atau dance yang ceria dan menghibur.",
        "comfort_food_to_eat": "Ice cream atau milkshake buat bikin mood lebih baik. Camilan manis seperti cupcake juga bisa jadi pilihan.",
        "what_to_do_to_ask_salary_increment_to_boss": "Tunjukkan bagaimana kontribusi kamu udah berpengaruh positif buat tim dan perusahaan. Sampaikan keinginan kamu dengan sopan dan jelas, serta jelaskan bagaimana kamu siap untuk tanggung jawab yang lebih besar."
    },
    "ESTP": {
        "when_under_pressure_on_work": "Cari cara praktis dan langsung buat selesaikan tugas. Break sebentar buat ngelakuin aktivitas fisik bisa bantu kamu fokus lagi. Jangan ragu buat cari informasi tambahan atau referensi buat solusi yang lebih baik.",
        "when_bored_on_work": "Coba proyek atau tugas baru yang lebih menantang. Libatkan diri dalam problem-solving atau mekanik yang membutuhkan keahlian teknis kamu. Ganti suasana kerja atau pindah tempat kerja sementara bisa bantu hilangin rasa bosan.",
        "kind_of_music_to_hear_when_tired": "Musik elektronik atau techno yang punya beat kuat buat bantu konsentrasi.",
        "comfort_food_to_eat": "Snack gurih seperti keripik atau kacang. Minuman energi atau kopi juga bisa bantu jaga stamina.",
        "what_to_do_to_ask_salary_increment_to_boss": "Bawa bukti nyata dari hasil kerja dan inovasi kamu. Sampaikan dengan tegas dan langsung, jelaskan bagaimana kamu bisa terus berkontribusi dengan lebih baik. Tunjukkan kesiapan kamu buat belajar dan berkembang."
    },
    "ISFP": {
        "when_under_pressure_on_work": "Ambil napas dalam-dalam dan coba buat rileks sebentar. Prioritaskan tugas yang paling penting dan selesaikan satu per satu. Jangan sungkan minta bantuan atau saran dari rekan kerja atau atasan.",
        "when_bored_on_work": "Cari tugas yang bisa membuat kamu merasa lebih bermakna. Terlibat dalam proyek yang membantu orang lain atau yang kamu pedulikan. Jangan ragu buat mengusulkan perubahan kecil yang bisa meningkatkan efisiensi kerja.",
        "kind_of_music_to_hear_when_tired": "Musik pop yang ringan atau lagu-lagu lama yang membawa nostalgia dan kenyamanan.",
        "comfort_food_to_eat": "Kue atau roti yang manis dan menenangkan. Teh hangat dengan madu juga bisa membantu kamu merasa lebih baik.",
        "what_to_do_to_ask_salary_increment_to_boss": "Persiapkan diri dengan catatan kontribusi dan bagaimana kamu telah membantu tim dan perusahaan. Sampaikan dengan rendah hati dan hormat, serta tunjukkan keinginan kamu untuk terus memberikan yang terbaik."
    },
    "ENTJ": {
        "when_under_pressure_on_work": "Bikin rencana strategis dan delegasikan tugas yang bisa dikerjakan oleh tim. Fokus pada solusi jangka panjang dan atur waktu untuk istirahat sebentar agar tetap produktif.",
        "when_bored_on_work": "Cari proyek besar yang menantang dan bisa memberikan dampak signifikan. Berkolaborasi dengan tim untuk mencapai target baru dan tingkatkan efisiensi kerja.",
        "kind_of_music_to_hear_when_tired": "Musik motivasi atau lagu-lagu dengan beat yang kuat buat membangkitkan semangat.",
        "comfort_food_to_eat": "Steak atau makanan yang lezat dan mengenyangkan. Kopi premium atau minuman berenergi juga bisa bantu jaga stamina.",
        "what_to_do_to_ask_salary_increment_to_boss": "Siapkan presentasi yang solid dengan data konkret tentang pencapaian dan kontribusi kamu. Sampaikan dengan percaya diri dan tunjukkan rencana kamu untuk membawa perusahaan ke level berikutnya."
    },
    "ESTJ": {
        "when_under_pressure_on_work": "Tetapkan prioritas dan buat to-do list yang jelas. Jangan ragu buat delegasikan tugas dan pastikan semua berjalan sesuai rencana. Ambil waktu sejenak buat refreshing pikiran.",
        "when_bored_on_work": "Cari cara buat meningkatkan efisiensi kerja atau perbaiki sistem yang ada. Terlibat dalam proyek baru yang bisa memberikan tantangan lebih. Ajak tim untuk brainstorming ide-ide segar.",
        "kind_of_music_to_hear_when_tired": "Musik rock atau pop dengan beat yang kuat buat membangkitkan semangat.",
        "comfort_food_to_eat": "Burger atau makanan cepat saji yang enak dan mengenyangkan. Minuman soda atau kopi dingin juga bisa jadi pilihan.",
        "what_to_do_to_ask_salary_increment_to_boss": "Siapkan data konkret tentang kontribusi dan hasil kerja kamu. Sampaikan dengan jelas dan tegas tentang nilai yang kamu berikan ke perusahaan. Tunjukkan kesiapan kamu untuk tanggung jawab yang lebih besar."
    },
    "ENFJ": {
        "when_under_pressure_on_work": "Cobalah buat tetap tenang dan terorganisir. Buat daftar tugas prioritas dan fokus pada satu tugas dalam satu waktu. Jangan ragu buat minta bantuan tim atau rekan kerja, dan ingat buat selalu komunikasi terbuka.",
        "when_bored_on_work": "Cobalah cari proyek baru yang bisa memberikan tantangan atau belajar keterampilan baru yang bisa membantu pekerjaan kamu. Ajak rekan kerja buat brainstorming ide-ide segar atau rencanakan acara kecil buat meningkatkan semangat tim.",
        "kind_of_music_to_hear_when_tired": "Musik pop atau akustik yang menenangkan dan bisa mengangkat suasana hati.",
        "comfort_food_to_eat": "Cokelat atau es krim yang manis dan bisa meningkatkan mood. Secangkir teh hangat atau kopi juga bisa membantu menenangkan pikiran.",
        "what_to_do_to_ask_salary_increment_to_boss": "Persiapkan argumen yang kuat dengan bukti konkret tentang kontribusi dan pencapaian kamu. Sampaikan dengan jujur dan tegas tentang harapan kamu, dan tunjukkan komitmen buat terus berkontribusi lebih di perusahaan."
    },
    "ISTJ": {
        "when_under_pressure_on_work": "Tetap tenang dan buat daftar tugas yang jelas. Prioritaskan tugas yang paling penting dan kerjakan satu per satu. Jangan ragu buat minta bantuan kalau perlu, dan ingat buat tetap fokus dan terorganisir.",
        "when_bored_on_work": "Cari proyek baru yang bisa menambah tantangan atau belajar keterampilan baru yang bisa mendukung pekerjaan kamu. Ajak rekan kerja untuk brainstorming ide-ide baru atau buat perubahan kecil dalam rutinitas harian kamu.",
        "kind_of_music_to_hear_when_tired": "Musik klasik atau instrumental yang menenangkan dan membantu kamu tetap fokus.",
        "comfort_food_to_eat": "Makanan rumahan seperti nasi goreng atau sop ayam yang hangat dan mengenyangkan. Teh hijau atau kopi hitam juga bisa membantu menghilangkan rasa lelah.",
        "what_to_do_to_ask_salary_increment_to_boss": "Persiapkan bukti konkret tentang kontribusi dan hasil kerja kamu. Sampaikan dengan jelas dan tegas tentang alasan kamu menginginkan kenaikan gaji, dan tunjukkan kesiapan kamu untuk mengambil tanggung jawab yang lebih besar."
    },
    "INTP": {
        "when_under_pressure_on_work": "Cobalah untuk tetap tenang dan gunakan logika untuk mengurai masalah. Buat rencana kerja yang terstruktur dan fokus pada solusi yang paling efisien. Jangan ragu untuk mencari informasi tambahan atau konsultasi dengan rekan kerja yang berpengalaman.",
        "when_bored_on_work": "Cobalah eksplorasi ide-ide baru atau proyek yang bisa memberikan stimulasi intelektual. Luangkan waktu untuk membaca artikel atau buku yang terkait dengan pekerjaanmu. Diskusi dengan rekan kerja tentang konsep-konsep baru juga bisa membantu mengatasi kebosanan.",
        "kind_of_music_to_hear_when_tired": "Musik instrumental atau klasik yang bisa membantu meningkatkan fokus dan kreativitas.",
        "comfort_food_to_eat": "Pizza atau makanan ringan yang bisa dinikmati sambil tetap bekerja. Minuman seperti kopi atau teh juga bisa membantu menyegarkan pikiran.",
        "what_to_do_to_ask_salary_increment_to_boss": "Siapkan data dan argumen yang kuat tentang kontribusi dan pencapaianmu. Gunakan logika dan fakta untuk mendukung permintaanmu. Sampaikan dengan jelas dan tenang tentang harapanmu, dan tunjukkan bagaimana kontribusimu bisa terus meningkatkan performa perusahaan."
    }
}


export const ProfileWithDesc = {
    "INTJ": {
        "short_description": "Si pemikir strategis yang selalu punya rencana dan solusi. Suka tantangan dan nggak takut buat ambil risiko."
    },
    "INFJ": {
        "short_description": "Si idealis yang selalu peduli sama orang lain. Punya intuisi kuat dan visi yang jelas buat masa depan."
    },
    "ENTP": {
        "short_description": "Si inovator yang suka eksplorasi ide-ide baru. Pinter debat dan selalu punya sudut pandang yang unik."
    },
    "ISFJ": {
        "short_description": "Si penjaga yang setia dan peduli sama kebutuhan orang lain. Suka kerja keras dan nggak banyak omong."
    },
    "ESFJ": {
        "short_description": "Si social butterfly yang suka bergaul dan membantu orang lain. Peduli sama lingkungan sekitar dan selalu ramah."
    },
    "ISTP": {
        "short_description": "Si problem solver yang praktis dan logis. Suka tantangan teknis dan lebih suka aksi daripada omong kosong."
    },
    "ENFP": {
        "short_description": "Si kreatif yang selalu antusias dan penuh energi. Suka ide-ide baru dan selalu mencari makna dalam segala hal."
    },
    "INFP": {
        "short_description": "Si idealis yang penuh empati dan kreativitas. Lebih suka refleksi dan introspeksi buat cari arti hidup."
    },
    "ESFP": {
        "short_description": "Si life of the party yang penuh semangat dan suka bersenang-senang. Selalu optimis dan suka hal-hal baru."
    },
    "ESTP": {
        "short_description": "Si action taker yang nggak takut ambil risiko. Suka tantangan dan lebih suka langsung terjun ke lapangan."
    },
    "ISFP": {
        "short_description": "Si seniman yang tenang dan penuh perasaan. Suka hal-hal estetis dan menikmati keindahan dalam kehidupan sehari-hari."
    },
    "ENTJ": {
        "short_description": "Si pemimpin yang tegas dan punya visi jelas. Fokus pada hasil dan nggak takut buat ambil keputusan besar."
    },
    "ESTJ": {
        "short_description": "Si organisator yang suka atur-atur dan pastikan semuanya berjalan lancar. Praktis dan selalu mengutamakan efisiensi."
    },
    "ENFJ": {
        "short_description": "Si pemimpin karismatik yang selalu peduli sama orang lain. Suka memotivasi dan membantu orang lain buat mencapai potensi terbaik."
    },
    "ISTJ": {
        "short_description": "Si organisator yang suka memastikan semuanya berjalan sesuai rencana. Mereka praktis, terstruktur, dan selalu berusaha mencapai efisiensi maksimal dalam segala hal."
    },
    "INTP": {
        "short_description": "Si pemikir yang selalu penasaran dan suka menganalisis segala sesuatu. Punya banyak ide brilian dan sering kali lebih suka kerja sendiri."
    }
}

export const LongDesc = {
    "ESTP": {
        "introduction": "Kalau kamu adalah ESTP, berarti kamu tipe yang suka banget tantangan dan beraksi cepat. Kamu adalah tipe orang yang nggak suka berlama-lama mikirin hal-hal kecil, lebih suka langsung terjun ke aksi. Kamu juga sangat berani mengambil risiko, dan kadang-kadang, kamu jadi pusat perhatian di mana pun berada. Energi dan semangatmu nggak ada habisnya, jadi orang-orang sering kali merasa terinspirasi saat berada di sekitarmu.\n\nKamu jago dalam membuat keputusan yang cepat dan seringkali sukses di lingkungan yang dinamis. Kamu mungkin bukan orang yang terlalu memikirkan detail, tapi kemampuanmu untuk beradaptasi dan bertindak di saat yang tepat adalah keunggulan utamamu. Jangan khawatir kalau kamu sering dipandang impulsif; kadang itu justru jadi kekuatanmu dalam mengambil peluang yang ada. Meski begitu, penting untuk tetap berhati-hati dan tidak terburu-buru dalam mengambil keputusan besar.\n\nKamu sering kali bisa menemukan solusi kreatif dalam situasi yang penuh tekanan. Teruslah mengejar petualangan dan tantangan, karena itu adalah bagian dari siapa dirimu yang sebenarnya."
    },
    "INFJ": {
        "introduction": "Sebagai INFJ, kamu adalah tipe orang yang penuh dengan rasa empati dan visi mendalam. Kamu suka bekerja di belakang layar, membantu orang lain dengan cara yang sangat berarti bagi mereka. Kamu dikenal sebagai seorang visioner yang selalu mencari makna dalam segala hal yang kamu lakukan. Meski kadang-kadang kamu merasa tertekan karena ekspektasi tinggi yang kamu pasang untuk dirimu sendiri, kamu selalu berusaha keras untuk membuat dunia menjadi tempat yang lebih baik.\n\nKamu juga punya kemampuan luar biasa untuk memahami perasaan orang lain dan memberikan dukungan emosional yang mereka butuhkan. Kecenderunganmu untuk berpikir jangka panjang membuatmu bisa melihat potensi besar dalam hal-hal kecil. Kamu lebih memilih hubungan yang mendalam dan berarti daripada hubungan yang banyak tapi superfisial. Jangan lupa untuk memberi waktu bagi dirimu sendiri dan menjaga kesehatan emosional.\n\nTeruslah mengikuti intuisi dan prinsip yang kamu yakini, karena itulah yang membedakanmu dari orang lain. Kamu punya kemampuan untuk membuat perubahan positif, jadi jangan ragu untuk terus berkarya dan berbagi visimu dengan dunia."
    },
    "ENTP": {
        "introduction": "Kalau kamu adalah ENTP, kamu adalah sosok yang penuh dengan ide dan kreativitas yang nggak ada habisnya. Kamu selalu mencari cara baru untuk menyelesaikan masalah dan nggak takut untuk mencoba hal-hal baru. Diskusi dan debat adalah hal yang kamu nikmati, dan seringkali kamu jadi pendorong utama dalam kelompok untuk berpikir di luar kebiasaan. Kamu punya bakat untuk melihat gambaran besar dan menghubungkan ide-ide yang tampaknya tidak berhubungan.\n\nKadang-kadang, kamu bisa jadi terlalu sering mengubah rencana atau terlalu kritis, tapi itu adalah bagian dari pesona kamu yang membuatmu sangat menarik. Kamu sangat fleksibel dan mampu beradaptasi dengan berbagai situasi, sehingga kamu sering kali menjadi orang yang diandalkan dalam situasi yang tidak terduga. Meski terkadang bisa terjebak dalam detail yang sepele, kemampuanmu untuk melihat potensi besar adalah kelebihanmu.\n\nJangan lupa untuk menyeimbangkan antara kreativitas dan tindakan nyata agar ide-idemu bisa terwujud dengan baik. Teruslah mengeksplorasi dan berbagi ide-ide brilianmu dengan dunia."
    },
    "ISFJ": {
        "introduction": "Kalau kamu adalah ISFJ, kamu adalah orang yang sangat perhatian dan setia. Kamu dikenal sebagai seseorang yang selalu siap membantu orang lain dengan cara yang praktis dan penuh kasih. Kamu suka menjaga keharmonisan di sekitarmu dan seringkali menjadi sosok yang bisa diandalkan dalam situasi apa pun. Kamu cenderung lebih suka bekerja di belakang layar dan memberikan dukungan yang diperlukan untuk mencapai tujuan bersama.\n\nKamu juga sangat detail-oriented, selalu memastikan bahwa semua hal dikerjakan dengan baik. Kecenderunganmu untuk mengutamakan kebutuhan orang lain di atas kebutuhanmu sendiri bisa membuatmu jadi sahabat yang sangat berharga. Meski begitu, kadang kamu bisa merasa kelelahan karena terlalu banyak mengalah. Penting untuk menjaga keseimbangan antara memberikan bantuan kepada orang lain dan merawat dirimu sendiri.\n\nTeruslah menunjukkan kepedulianmu yang mendalam dan jangan ragu untuk meminta bantuan jika diperlukan. Kamu punya kemampuan luar biasa untuk menciptakan lingkungan yang nyaman dan harmonis."
    },
    "INTJ": {
        "introduction": "Sebagai INTJ, kamu adalah orang yang selalu punya rencana matang dan strategi yang jelas. Kamu sering kali dipandang sebagai seorang yang visioner dan analitis, karena kamu cenderung berpikir jauh ke depan dan merencanakan segala sesuatunya dengan cermat. Kamu lebih suka bekerja secara mandiri dan seringkali memiliki standar yang sangat tinggi untuk dirimu sendiri. Kadang-kadang, kamu bisa dianggap terlalu kritis atau kaku, tapi itu semua karena kamu sangat fokus pada pencapaian tujuan.\n\nKamu jago dalam memecahkan masalah kompleks dan mencari solusi yang efektif. Meskipun kamu mungkin tidak selalu menunjukkan perasaanmu secara terbuka, kamu punya komitmen yang kuat untuk mencapai hasil yang terbaik. Penting untuk tetap terbuka terhadap masukan dan berkolaborasi dengan orang lain agar ide-ide cemerlangmu bisa terwujud. Jangan lupa untuk memberi dirimu waktu istirahat dan mengelola stres agar tetap produktif dan sehat.\n\nTeruslah mengikuti visimu dan tetaplah menjadi diri sendiri."
    },
    "ESFP": {
        "introduction": "Kalau kamu adalah ESFP, kamu pasti dikenal sebagai orang yang ceria dan penuh energi. Kamu suka bergaul dan berada di tengah keramaian, serta menikmati setiap momen hidup dengan semangat. Kamu adalah sosok yang spontan dan selalu siap untuk merayakan kehidupan dengan cara yang penuh warna. Energi positifmu menular ke orang-orang di sekitarmu, dan kamu sering kali menjadi pusat perhatian di setiap acara.\n\nKamu juga sangat fleksibel dan bisa beradaptasi dengan cepat terhadap situasi baru. Meski terkadang bisa terlalu bergantung pada kesenangan dan menghindari masalah, kemampuanmu untuk menciptakan suasana yang menyenangkan adalah keunggulan utamamu. Jangan lupa untuk menjaga keseimbangan antara kesenangan dan tanggung jawab. Teruslah berbagi keceriaanmu dengan orang lain dan jangan takut untuk mengejar impianmu dengan penuh semangat.\n\nKamu punya kemampuan luar biasa untuk membuat hidup lebih berwarna dan menarik."
    },
    "INFP": {
        "introduction": "Sebagai INFP, kamu adalah orang yang idealis dan penuh perasaan. Kamu sering kali mencari makna yang lebih dalam dalam hidup dan selalu berusaha untuk membuat dunia menjadi tempat yang lebih baik. Kamu dikenal sebagai sosok yang sangat kreatif dan empatik, dan kamu punya kemampuan untuk memahami perasaan orang lain dengan mendalam. Dalam pekerjaan atau hubungan, kamu lebih suka berfokus pada nilai-nilai dan tujuan yang lebih besar daripada sekadar hasil materi.\n\nKadang-kadang, kamu bisa merasa terlalu sensitif atau idealis, tapi itulah yang membuatmu unik. Kamu juga mungkin menghadapi tantangan dalam menghadapi kritik, tapi itu semua bagian dari proses berkembang. Penting untuk tetap percaya pada dirimu sendiri dan mengejar impianmu dengan penuh semangat. Jangan ragu untuk mencari dukungan dan berbagi perasaanmu dengan orang-orang terdekat.\n\nTeruslah mengikuti kata hatimu dan berkontribusi pada dunia dengan cara yang positif."
    },
    "ISTP": {
        "introduction": "Kalau kamu adalah ISTP, kamu adalah orang yang praktis dan mandiri. Kamu suka memecahkan masalah dengan cara yang hands-on dan sering kali memiliki keterampilan teknis yang luar biasa. Kamu cenderung tenang dan tidak mudah terpengaruh oleh situasi yang penuh tekanan. Kamu juga dikenal sebagai sosok yang terampil dan efisien dalam menyelesaikan tugas.\n\nKadang-kadang, kamu bisa dianggap tertutup atau kurang ekspresif, tapi itu semua karena kamu lebih suka menyelesaikan masalah dengan tindakan daripada berbicara. Kamu juga mungkin tidak terlalu fokus pada detail, tapi kemampuanmu untuk beradaptasi dan menyelesaikan tugas secara efektif adalah keunggulanmu. Penting untuk terus melatih keterampilanmu dan mencari cara baru untuk menghadapi tantangan.\n\nJangan ragu untuk berbagi ide-ide atau mencari kolaborasi jika diperlukan. Teruslah mengejar minat dan keterampilanmu dengan penuh semangat."
    },
    "ESFJ": {
        "introduction": "Sebagai ESFJ, kamu adalah orang yang ramah dan sangat peduli dengan orang di sekitarmu. Kamu suka menjaga hubungan baik dan selalu siap membantu orang lain dalam berbagai situasi. Kamu dikenal sebagai sosok yang terorganisir dan dapat diandalkan, sering kali menjadi pusat perhatian dalam kelompok sosial. Kamu juga sangat terampil dalam menjaga keharmonisan dan menciptakan lingkungan yang nyaman bagi semua orang.\n\nKadang-kadang, kamu bisa merasa terlalu bergantung pada pendapat orang lain atau sulit menolak permintaan, tapi itulah yang membuatmu jadi sahabat yang sangat berharga. Kamu juga mungkin menghadapi tantangan dalam menghadapi perubahan, tapi kemampuanmu untuk beradaptasi dan menjaga hubungan tetap baik adalah keunggulanmu. Teruslah berbagi kebaikan dan menjaga hubungan yang berarti dengan orang-orang di sekitarmu.\n\nKamu punya kemampuan luar biasa untuk menciptakan suasana yang harmonis dan menyenangkan."
    },
    "ENTJ": {
        "introduction": "Kalau kamu adalah ENTJ, kamu adalah sosok yang sangat ambisius dan penuh tekad. Kamu dikenal sebagai seorang pemimpin yang natural dan selalu siap untuk menghadapi tantangan besar. Kamu punya kemampuan luar biasa untuk merencanakan dan mengorganisir berbagai hal dengan efektif. Kamu cenderung fokus pada pencapaian tujuan dan tidak takut untuk mengambil keputusan penting.\n\nKadang-kadang, kamu bisa dianggap terlalu dominan atau keras kepala, tapi itu semua karena kamu sangat berkomitmen untuk mencapai hasil yang terbaik. Kamu juga mungkin menghadapi tantangan dalam hal kesabaran atau berbagi kendali, tapi kemampuanmu untuk memimpin dan memotivasi orang lain adalah keunggulan utamamu. Teruslah mengejar ambisimu dengan penuh semangat dan jangan ragu untuk bekerja sama dengan orang lain.\n\nKamu punya kemampuan luar biasa untuk menciptakan perubahan positif dan mencapai tujuan besar."
    },
    "ISTJ": {
        "introduction": "Sebagai ISTJ, kamu adalah orang yang sangat teratur dan dapat diandalkan. Kamu cenderung fokus pada detail dan suka mengikuti aturan serta prosedur yang ada. Kamu dikenal sebagai sosok yang konsisten dan bertanggung jawab, sering kali menjadi contoh bagi orang lain dalam hal etika kerja. Kamu juga sangat terampil dalam merencanakan dan melaksanakan tugas dengan sistematis.\n\nKadang-kadang, kamu bisa dianggap kaku atau terlalu terikat pada aturan, tapi itu semua karena kamu sangat menghargai kestabilan dan ketertiban. Kamu juga mungkin menghadapi tantangan dalam beradaptasi dengan perubahan mendadak, tapi kemampuanmu untuk menjaga struktur dan organisasi adalah keunggulanmu. Teruslah menjaga integritas dan komitmenmu dalam pekerjaan.\n\nKamu punya kemampuan luar biasa untuk menyelesaikan tugas dengan baik dan menjaga standar yang tinggi."
    },
    "INTP": {
        "introduction": "Kalau kamu adalah INTP, kamu adalah orang yang penuh dengan ide-ide cemerlang dan suka berpikir mendalam. Kamu dikenal sebagai sosok yang sangat analitis dan sering kali terjebak dalam pemikiran filosofis yang kompleks. Kamu suka menggali berbagai kemungkinan dan tidak takut untuk mengeksplorasi konsep-konsep baru. Kamu juga sering kali menjadi sumber ide-ide inovatif di kelompok dan menikmati tantangan intelektual.\n\nKadang-kadang, kamu bisa merasa terasing atau kurang terhubung dengan dunia luar karena fokusmu yang mendalam, tapi itu semua karena kamu lebih suka bekerja dengan ide-ide dan teori. Kamu mungkin menghadapi tantangan dalam hal komunikasi atau organisasi, tapi kemampuanmu untuk berpikir secara kritis adalah keunggulanmu. Teruslah mengejar pengetahuan dan jangan ragu untuk berbagi ide-idemu dengan orang lain.\n\nJangan lupa untuk menjaga keseimbangan antara pemikiran dan tindakan, agar ide-ide cemerlangmu bisa terwujud."
    },
    "ENFJ": {
        "introduction": "Kalau kamu adalah ENFJ, kamu adalah orang yang sangat peduli dan penuh perhatian terhadap orang lain. Kamu dikenal sebagai sosok yang karismatik dan punya kemampuan luar biasa dalam memahami perasaan orang di sekitarmu. Kamu sering kali menjadi pemimpin yang inspiratif, selalu memotivasi dan mendukung orang lain untuk mencapai potensi terbaik mereka. Kamu sangat berbakat dalam menciptakan hubungan yang harmonis dan penuh makna.\n\nKamu juga sangat antusias dan energik, sering kali menjadi sumber semangat bagi tim atau komunitasmu. Meski kadang-kadang kamu bisa merasa terbebani oleh tanggung jawab besar atau ekspektasi tinggi, kemampuanmu untuk tetap positif dan optimis adalah kekuatan utamamu. Jangan lupa untuk memberikan waktu bagi dirimu sendiri dan menjaga keseimbangan antara membantu orang lain dan merawat dirimu sendiri.\n\nKamu punya kemampuan luar biasa untuk membuat perbedaan positif dalam hidup banyak orang dan menciptakan lingkungan yang mendukung dan penuh kasih."
    },
    "ENFP": {
        "introduction": "Kalau kamu adalah ENFP, kamu adalah orang yang kreatif dan penuh semangat. Kamu selalu antusias dengan ide-ide baru dan suka mencari makna dalam segala hal. Kamu dikenal sebagai orang yang ramah dan suka bergaul, sering kali menjadi pusat perhatian dalam pertemuan sosial.\n\nKamu punya kemampuan luar biasa untuk berpikir out-of-the-box dan menemukan solusi kreatif untuk berbagai masalah. Kamu juga sangat fleksibel dan mudah beradaptasi dengan perubahan, membuat kamu bisa bekerja di berbagai lingkungan dan situasi. Meski kadang-kadang kamu bisa mudah bosan dengan rutinitas, semangatmu untuk eksplorasi dan petualangan adalah kekuatan utamamu.\n\nKamu sangat menghargai hubungan yang mendalam dan bermakna dengan orang lain. Kamu juga punya bakat alami untuk memotivasi dan menginspirasi orang di sekitarmu, membuat mereka merasa dihargai dan didukung. Jangan lupa untuk memberikan waktu bagi dirimu sendiri untuk beristirahat dan refleksi, agar kamu bisa terus berkembang dan membawa dampak positif bagi banyak orang.\n\nKamu punya kemampuan luar biasa untuk menciptakan perubahan positif dalam dunia dan menjadikan tempat di sekitarmu lebih berwarna dan penuh kehidupan."
    },
    "ISFP": {
        "introduction": "Kalau kamu adalah ISFP, kamu adalah orang yang sangat menghargai keindahan dan seni dalam hidup. Kamu cenderung tenang, santai, dan penuh perasaan. Kamu suka menikmati momen-momen kecil yang memberikan kebahagiaan dan makna.\n\nSebagai individu yang kreatif, kamu sering kali mencari cara untuk mengekspresikan diri melalui seni, musik, atau aktivitas lainnya yang melibatkan imajinasi. Kamu punya kemampuan luar biasa untuk melihat keindahan di tempat-tempat yang mungkin diabaikan orang lain. Selain itu, kamu sangat empatik dan selalu siap membantu orang lain.\n\nMeski kamu lebih suka bekerja secara mandiri dan dalam suasana yang tenang, kamu tetap bisa bekerja dengan baik dalam tim asalkan ada ruang untuk kreativitas. Kamu juga sangat menghargai kebebasan dan fleksibilitas dalam bekerja. Kadang-kadang kamu mungkin merasa sulit untuk menghadapi kritik, tetapi ingatlah bahwa pendapat orang lain bukanlah akhir dari segalanya. Teruslah menjadi dirimu sendiri dan jadikan dunia ini tempat yang lebih indah dengan kehadiranmu."
    },
    "ESTJ": {
        "introduction": "Kalau kamu adalah ESTJ, kamu adalah orang yang sangat terorganisir dan suka mengatur segalanya agar berjalan lancar. Kamu dikenal sebagai pemimpin alami yang tegas dan sangat fokus pada hasil. Kamu suka memastikan semua orang mengikuti rencana dan target yang telah ditetapkan.\n\nKamu sangat praktis dan realistis, selalu mencari cara untuk meningkatkan efisiensi dan produktivitas. Dalam lingkungan kerja, kamu sangat bisa diandalkan dan sering kali menjadi orang yang diandalkan untuk menyelesaikan tugas-tugas penting. Kamu juga sangat menghargai tradisi dan nilai-nilai yang sudah terbukti berhasil.\n\nMeski kadang kamu bisa terlihat keras atau kaku, niatmu selalu baik dan kamu hanya ingin yang terbaik untuk tim atau organisasimu. Kamu mungkin perlu belajar untuk lebih fleksibel dan mendengarkan ide-ide baru dari orang lain. Ingatlah bahwa perubahan bisa membawa kemajuan dan jangan takut untuk mencoba pendekatan yang berbeda.\n\nKamu punya potensi besar untuk menjadi pemimpin yang hebat dan bisa menginspirasi orang lain dengan dedikasi dan komitmenmu terhadap pekerjaan."
    }
}


export const SideHustleSuggestion = {
    "ESTP": {
        "suitable_side_hustle_job": ["Penjual Online", "Influencer", "Agen Properti"],
        "suggestion": "Manfaatkan energi dan keterampilan komunikasi kamu buat ngembangin jaringan dan peluang bisnis baru. Kamu cenderung cepat beradaptasi dan berpikir cepat, jadi jangan takut ambil inisiatif dan buat keputusan cepat. Di lingkungan kerja, jadilah pusat perhatian dengan cara yang positif dan teruslah belajar dari pengalaman.",
        "beware": "Hindari ambil risiko terlalu besar tanpa perencanaan matang. Jangan terlalu impulsif karena bisa berakibat fatal dalam jangka panjang. Tetap pertimbangkan konsekuensi dari setiap tindakan kamu. Di tempat kerja, usahakan untuk mendengarkan rekan kerja dan atasan, serta hargai masukan dari mereka agar tidak terkesan egois.",
        "words_of_encouragement": "Kamu punya karisma dan energi yang luar biasa. Gunakan itu buat menginspirasi orang lain dan mencapai kesuksesan.",
        "friendship_dynamics": "Kamu adalah teman yang menyenangkan dan energik, selalu membawa keceriaan dalam grup. Namun, pastikan juga mendengarkan cerita dan kebutuhan teman-temanmu.",
        "suitable_sports": ["Basketball", "Parkour", "Sepak Bola"],
        "workaholic_meter": "8/10"
    },
    "INFJ": {
        "suitable_side_hustle_job": ["Penulis", "Konselor Online", "Pelukis"],
        "suggestion": "Pake intuisi dan empati kamu buat bantu orang lain dan bikin karya yang bermakna. Kamu cenderung memahami perasaan orang lain dengan baik, jadi manfaatkan ini untuk menciptakan lingkungan kerja yang harmonis. Terus berikan ide-ide kreatif dan pandangan jangka panjang kamu untuk menginspirasi tim.",
        "beware": "Jangan terlalu keras sama diri sendiri dan hindari terjebak dalam perfeksionisme. Luangin waktu buat diri sendiri untuk relaksasi dan refleksi. Di tempat kerja, usahakan untuk tidak terlalu terbawa emosi dan tetap rasional saat menghadapi konflik.",
        "words_of_encouragement": "Visi kamu yang dalam dan perasaan yang tulus adalah kekuatan besar. Kamu bisa bikin perubahan positif di dunia.",
        "friendship_dynamics": "Kamu adalah teman yang setia dan penuh perhatian, selalu siap mendengarkan dan memberi dukungan emosional. Namun, jangan lupakan kebutuhanmu sendiri.",
        "suitable_sports": ["Yoga", "Tai Chi", "Mendaki"],
        "workaholic_meter": "6/10"
    },
    "ENTP": {
        "suitable_side_hustle_job": ["Konsultan Bisnis", "Youtuber", "Penulis Blog"],
        "suggestion": "Manfaatin kreativitas dan kecerdasan kamu buat nemuin solusi inovatif dan kejar proyek yang menantang. Di lingkungan kerja, jadilah inisiator ide-ide baru dan tetap berani mengajukan perubahan. Pastikan kamu juga mendokumentasikan ide-ide kamu agar bisa dieksekusi dengan baik.",
        "beware": "Jangan terlalu sering pindah-pindah fokus. Tetap konsisten biar ide-ide kamu bisa terrealisasi. Hindari terlalu banyak debat tanpa hasil yang jelas. Di tempat kerja, usahakan untuk menjaga keseimbangan antara inovasi dan stabilitas.",
        "words_of_encouragement": "Kamu cerdas dan penuh ide. Terus berinovasi dan percaya sama kemampuan kamu!",
        "friendship_dynamics": "Kamu adalah teman yang seru dan selalu membawa perspektif baru. Terkadang bisa terlalu argumentatif, jadi ingat untuk menghargai pendapat teman-temanmu.",
        "suitable_sports": ["Tennis", "Running", "Esports"],
        "workaholic_meter": "7/10"
    },
    "ISFJ": {
        "suitable_side_hustle_job": ["Baker", "Perajin", "Tutor Pribadi"],
        "suggestion": "Gunain sifat perhatian dan kesetiaan kamu buat ngebantu orang lain dan bikin lingkungan yang harmonis. Di lingkungan kerja, kamu bisa jadi support system yang andal dan bekerja secara efisien. Terus jaga ketertiban dan rutinitas agar semua berjalan lancar.",
        "beware": "Jangan terlalu sering mengorbankan diri. Jaga kesehatan dan keseimbangan hidup. Di tempat kerja, jangan takut untuk mengatakan tidak ketika beban kerja sudah terlalu berat. Hargai waktu dan batasan pribadi kamu.",
        "words_of_encouragement": "Kamu sangat bisa diandalkan dan perhatian. Terus bantu orang lain dengan kebaikan hati kamu.",
        "friendship_dynamics": "Kamu adalah teman yang setia dan selalu siap membantu. Namun, pastikan juga menjaga keseimbangan antara membantu teman dan merawat diri sendiri.",
        "suitable_sports": ["Badminton", "Swimming", "Pilates"],
        "workaholic_meter": "7/10"
    },
    "INTJ": {
        "suitable_side_hustle_job": ["Analis Data", "Penulis Teknologi", "Konsultan Bisnis"],
        "suggestion": "Manfaatin kemampuan analitis dan strategi kamu buat merencanakan masa depan dan kerja secara independen. Di lingkungan kerja, kamu bisa menjadi pemimpin yang visioner dan efektif. Terus asah keterampilan analitis dan tetap buka pada ide-ide baru.",
        "beware": "Jangan terlalu kritis pada diri sendiri dan orang lain. Jaga fleksibilitas dan pertimbangkan perasaan orang lain dalam pengambilan keputusan. Di tempat kerja, jangan terlalu kaku dalam menjalankan rencana. Bersikaplah terbuka pada masukan dari rekan kerja.",
        "words_of_encouragement": "Kamu punya visi yang jelas dan kemampuan hebat. Terus kejar impian dengan tekad kuat.",
        "friendship_dynamics": "Kamu adalah teman yang cerdas dan penuh wawasan, selalu membawa perspektif baru. Terkadang bisa terlalu analitis, jadi ingat untuk juga memperhatikan perasaan teman-temanmu.",
        "suitable_sports": ["Chess", "Fencing", "Martial Arts"],
        "workaholic_meter": "8/10"
    },
    "ESFP": {
        "suitable_side_hustle_job": ["MC Acara", "Instruktur Kebugaran", "Fotografer"],
        "suggestion": "Gunain sifat ceria dan spontan kamu buat nyemangatin orang lain dan nikmati setiap momen. Di lingkungan kerja, kamu bisa menjadi motivator yang hebat dan menciptakan suasana kerja yang menyenangkan. Jangan ragu untuk mengekspresikan kreativitas dan ide-ide baru.",
        "beware": "Jangan terlalu fokus pada kesenangan aja. Pikirin juga jangka panjang dan pertimbangkan dampak dari keputusan impulsif. Di tempat kerja, usahakan untuk tetap fokus pada tugas dan hindari distraksi yang berlebihan.",
        "words_of_encouragement": "Kamu penuh semangat dan fleksibel. Terus sebarkan kebahagiaan ke sekeliling kamu.",
        "friendship_dynamics": "Kamu adalah teman yang menyenangkan dan selalu siap bersenang-senang. Namun, pastikan juga mendengarkan cerita dan kebutuhan teman-temanmu.",
        "suitable_sports": ["Dance", "Soccer", "Surfing"],
        "workaholic_meter": "6/10"
    },
    "INFP": {
        "suitable_side_hustle_job": ["Penulis Puisi", "Ilustrator", "Terapis Alternatif"],
        "suggestion": "Pake idealisme dan kreativitas kamu buat nyari makna yang lebih dalam dalam hidup. Di lingkungan kerja, kamu bisa menjadi inspirator dengan ide-ide yang penuh makna dan empati. Terus ciptakan karya yang bermakna dan berkontribusi positif pada tim.",
        "beware": "Jangan terlalu sensitif dan idealis. Hadapi kritik dengan tenang dan realistis. Di tempat kerja, hindari terlalu terbawa perasaan dan tetap fokus pada tujuan bersama.",
        "words_of_encouragement": "Kamu penuh perasaan dan empati. Terus ciptakan karya yang bermakna dan ikuti hati kamu.",
        "friendship_dynamics": "Kamu adalah teman yang penuh perasaan dan selalu siap mendukung. Namun, jangan lupakan kebutuhanmu sendiri dan jaga keseimbangan.",
        "suitable_sports": ["Yoga", "Pilates", "Journaling"],
        "workaholic_meter": "5/10"
    },
    "ISTP": {
        "suitable_side_hustle_job": ["Montir", "Teknisi", "Pekerja Lapangan"],
        "suggestion": "Manfaatin sifat praktis dan mandiri kamu buat mecahin masalah dengan cara hands-on. Di lingkungan kerja, kamu bisa menjadi problem solver yang handal. Terus eksplorasi cara-cara baru untuk meningkatkan efisiensi dan kualitas kerja.",
        "beware": "Jangan terlalu tertutup dan kurang ekspresif. Belajar buat lebih terbuka dan komunikatif dengan rekan kerja. Di tempat kerja, hindari isolasi dan cobalah untuk lebih berkolaborasi dengan tim.",
        "words_of_encouragement": "Kamu terampil dan tenang. Terus asah kemampuan dan kejar hal-hal yang kamu suka.",
        "friendship_dynamics": "Kamu adalah teman yang tenang dan andal, selalu siap membantu. Namun, pastikan juga menunjukkan perhatian pada teman-temanmu.",
        "suitable_sports": ["Rock Climbing", "Motorcycling", "Archery"],
        "workaholic_meter": "7/10"
    },
    "ESFJ": {
        "suitable_side_hustle_job": ["Wedding Planner", "Host Acara", "Guru Privat"],
        "suggestion": "Pake sifat ramah dan terorganisir kamu buat menjaga hubungan baik dan bantu orang di sekitar kamu. Di lingkungan kerja, kamu bisa menjadi mediator yang handal dan menciptakan lingkungan kerja yang harmonis. Terus jaga komunikasi yang baik dan pertahankan keteraturan.",
        "beware": "Jangan terlalu bergantung pada pendapat orang lain. Jaga fleksibilitas dan pertimbangkan juga kebutuhan pribadi kamu. Di tempat kerja, hindari terlalu memikirkan apa yang orang lain pikirkan dan fokus pada kualitas kerja kamu.",
        "words_of_encouragement": "Kamu sangat peduli dan bisa diandalkan. Terus bantu orang lain dengan hati yang besar.",
        "friendship_dynamics": "Kamu adalah teman yang setia dan perhatian, selalu siap membantu. Namun, jangan lupakan kebutuhanmu sendiri dan jaga keseimbangan.",
        "suitable_sports": ["Volleyball", "Aerobics", "Dancing"],
        "workaholic_meter": "8/10"
    },
    "INTP": {
        "suitable_side_hustle_job": ["Programmer Freelance", "Penulis Teknologi", "Peneliti Independen"],
        "suggestion": "Manfaatin kemampuan analitis dan kreatif kamu buat mengeksplorasi ide-ide baru. Di lingkungan kerja, kamu bisa menjadi inovator yang brilian. Terus asah kemampuan berpikir kritis dan terbuka pada perspektif baru.",
        "beware": "Jangan terlalu kritis dan terpusat pada pikiran sendiri. Belajar buat lebih terbuka pada orang lain dan hargai kontribusi mereka. Di tempat kerja, usahakan untuk berkomunikasi lebih efektif dan jangan terlalu terpaku pada detail teknis.",
        "words_of_encouragement": "Kamu sangat cerdas dan kreatif. Terus eksplorasi dan ciptakan ide-ide brilian.",
        "friendship_dynamics": "Kamu adalah teman yang cerdas dan penuh wawasan, selalu membawa perspektif baru. Terkadang bisa terlalu analitis, jadi ingat untuk juga memperhatikan perasaan teman-temanmu.",
        "suitable_sports": ["Chess", "Fencing", "Martial Arts"],
        "workaholic_meter": "8/10"
    },
    "ISFP": {
        "suitable_side_hustle_job": ["Fotografer", "Desainer", "Perajin"],
        "suggestion": "Gunain sifat lembut dan artistik kamu buat menikmati keindahan hidup. Di lingkungan kerja, kamu bisa menjadi kreator yang handal dan menciptakan karya yang indah. Terus jaga keseimbangan antara pekerjaan dan kehidupan pribadi.",
        "beware": "Jangan terlalu sensitif dan kurang terstruktur. Jaga keseimbangan antara perasaan dan logika. Di tempat kerja, usahakan untuk lebih terorganisir dan tetap fokus pada tugas.",
        "words_of_encouragement": "Kamu penuh perasaan dan kreatif. Terus nikmati hidup dan ciptakan karya yang indah.",
        "friendship_dynamics": "Kamu adalah teman yang penuh perasaan dan selalu siap mendukung. Namun, jangan lupakan kebutuhanmu sendiri dan jaga keseimbangan.",
        "suitable_sports": ["Yoga", "Pilates", "Journaling"],
        "workaholic_meter": "5/10"
    },
    "ENFP": {
        "suitable_side_hustle_job": ["Blogger", "Pelatih Motivasi", "Penggiat Sosial"],
        "suggestion": "Manfaatin semangat dan kreativitas kamu buat eksplorasi kemungkinan baru. Di lingkungan kerja, kamu bisa menjadi inspirator yang penuh ide. Terus berinovasi dan tetap fleksibel dalam menghadapi perubahan.",
        "beware": "Jangan terlalu sering berubah arah dan mudah bosan. Fokus dan selesaikan apa yang kamu mulai. Di tempat kerja, hindari terlalu banyak proyek yang berjalan bersamaan dan tetap disiplin dengan deadline.",
        "words_of_encouragement": "Kamu penuh ide dan semangat. Terus berinovasi dan kejar mimpi-mimpi kamu.",
        "friendship_dynamics": "Kamu adalah teman yang seru dan selalu membawa perspektif baru. Terkadang bisa terlalu argumentatif, jadi ingat untuk menghargai pendapat teman-temanmu.",
        "suitable_sports": ["Tennis", "Running", "Esports"],
        "workaholic_meter": "7/10"
    },
    "ENTJ": {
        "suitable_side_hustle_job": ["Konsultan Bisnis", "Pembicara Publik", "Manajer Proyek"],
        "suggestion": "Gunain sifat kepemimpinan dan terorganisir kamu buat merencanakan dan mengarahkan proyek besar. Di lingkungan kerja, kamu bisa menjadi pemimpin yang efektif dan visioner. Terus jaga fokus pada tujuan dan pertahankan standar tinggi.",
        "beware": "Jangan terlalu dominan dan kurang empati. Belajar buat lebih memahami perasaan orang lain dan berkolaborasi dengan tim. Di tempat kerja, hindari terlalu banyak memaksakan kehendak dan terbuka pada masukan dari rekan kerja.",
        "words_of_encouragement": "Kamu punya kemampuan kepemimpinan yang hebat. Terus pimpin dengan visi yang jelas dan tekad kuat.",
        "friendship_dynamics": "Kamu adalah teman yang ambisius dan inspiratif, selalu mendorong orang untuk berbuat lebih baik. Terkadang bisa terlalu mendominasi, jadi ingat untuk juga mendengarkan teman-temanmu.",
        "suitable_sports": ["Basketball", "Martial Arts", "Weightlifting"],
        "workaholic_meter": "9/10"
    },
    "ISTJ": {
        "suitable_side_hustle_job": ["Akuntan Freelance", "Pengelola Properti", "Administrator"],
        "suggestion": "Manfaatin sifat terorganisir dan bisa diandalkan kamu buat bekerja dengan cara yang sistematis. Di lingkungan kerja, kamu bisa menjadi pilar yang menjaga stabilitas dan keteraturan. Terus jaga efisiensi dan patuhi prosedur kerja.",
        "beware": "Jangan terlalu kaku dan kurang fleksibel. Belajar buat lebih terbuka pada perubahan dan inovasi. Di tempat kerja, hindari terlalu terpaku pada cara-cara lama dan cobalah pendekatan baru yang lebih efektif.",
        "words_of_encouragement": "Kamu sangat bisa diandalkan dan detail-oriented. Terus jaga disiplin dan kejar kesempurnaan.",
        "friendship_dynamics": "Kamu adalah teman yang setia dan bisa diandalkan, selalu siap membantu. Namun, pastikan juga menjaga keseimbangan antara membantu teman dan merawat diri sendiri.",
        "suitable_sports": ["Badminton", "Swimming", "Pilates"],
        "workaholic_meter": "8/10"
    },
    "ESTJ": {
        "suitable_side_hustle_job": ["Manajer Proyek", "Konsultan Keuangan", "Petugas Hukum"],
        "suggestion": "Pake sifat tegas dan terorganisir kamu buat fokus pada hasil dan mencapai tujuan. Di lingkungan kerja, kamu bisa menjadi pemimpin yang efektif dan memastikan semuanya berjalan sesuai rencana. Terus jaga disiplin dan dorong tim untuk mencapai standar tinggi.",
        "beware": "Jangan terlalu dominan dan kurang empati. Belajar buat lebih mendengarkan orang lain dan berkolaborasi dengan tim. Di tempat kerja, hindari terlalu banyak memaksakan kehendak dan terbuka pada masukan dari rekan kerja.",
        "words_of_encouragement": "Kamu punya kemampuan kepemimpinan yang kuat. Terus capai tujuan dengan tekad dan disiplin.",
        "friendship_dynamics": "Kamu adalah teman yang ambisius dan inspiratif, selalu mendorong orang untuk berbuat lebih baik. Terkadang bisa terlalu mendominasi, jadi ingat untuk juga mendengarkan teman-temanmu.",
        "suitable_sports": ["Basketball", "Martial Arts", "Weightlifting"],
        "workaholic_meter": "9/10"
    },
    "ENFJ": {
        "suitable_side_hustle_job": ["Konselor", "Guru Online", "Pembicara Motivasi"],
        "suggestion": "Gunain sifat karismatik dan inspiratif kamu buat memotivasi orang lain dan menjadi pemimpin alami. Di lingkungan kerja, kamu bisa menjadi motivator yang handal dan menciptakan lingkungan kerja yang harmonis. Terus jaga komunikasi yang baik dan bantu tim mencapai potensi terbaik mereka.",
        "beware": "Jangan terlalu mengandalkan perasaan dan sulit menolak permintaan. Jaga keseimbangan antara membantu orang lain dan merawat diri sendiri. Di tempat kerja, hindari terlalu banyak tugas yang bisa membebani kamu dan belajar untuk mengatakan tidak.",
        "words_of_encouragement": "Kamu sangat inspiratif dan karismatik. Terus bantu orang lain mencapai potensi mereka.",
        "friendship_dynamics": "Kamu adalah teman yang setia dan perhatian, selalu siap membantu. Namun, jangan lupakan kebutuhanmu sendiri dan jaga keseimbangan.",
        "suitable_sports": ["Volleyball", "Aerobics", "Dancing"],
        "workaholic_meter": "8/10"
    }
}
