import React from "react";
import * as Pi from 'react-icons/pi';
import { SectionContainer, SectionWrapper } from "../../pages/style";
import { FooterContainer, FooterFlex, FooterLeft, FooterSocial } from "./style";

export const Footer = () => {
    return (
        <FooterContainer>
            <SectionWrapper style={{ marginBottom: "1rem" }}>
                <SectionContainer>
                    <FooterFlex>
                        <FooterLeft>
                            <h1>intrn</h1>
                            <span>Your go-to spot for all Internship things. We help students resume from going to the trash.</span>
                            <p>© 2024 INTRN - made with 🫶🏻 <a href="https://bykace.com" target="_blank" rel="noreferrer">bykace</a></p>
                        </FooterLeft>
                        <FooterSocial>
                            <a href="https://instagram.com/intrn.id" target="_blank" rel="noreferrer">
                                <Pi.PiInstagramLogo size={25} />
                            </a>
                        </FooterSocial>
                    </FooterFlex>
                </SectionContainer>
            </SectionWrapper>
        </FooterContainer>
    )
}