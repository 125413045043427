import React, { useEffect, useState } from "react";
import * as Ci from 'react-icons/ci';
import * as Go from 'react-icons/go';
import * as Md from 'react-icons/md';
import { SectionContainer, SectionWrapper } from "../../pages/style";
import { timeSince } from "../../utils";
import { Internships } from "./data";
import { JobCard, JobContainer, JobContainerFooter, JobContainerRole, JobContainerTop, JobDetailFlex, JobFooter, JobFooterDetail, JobFooterFlex, JobGrid, JobLoadMore, LoadMoreButton } from "./style";

export const Jobs = () => {
    const [data] = useState(Internships);
    const [filtered, setFiltered] = useState([]);
    const [start, setStart] = useState(0);
    const [total, setTotal] = useState(6);

    useEffect(() => {
        fetchFiltered();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFiltered = () => {
        const filtered = data.slice(start, total);
        setFiltered(filtered);
    }

    const onLoadMore = () => {
        const loadedData = data.slice(start + 6, total + 6);
        const newFiltered = [...filtered, ...loadedData];
        setFiltered(newFiltered);
        setStart(start + 6);
        setTotal(total + 6);
    }

    return (
        <SectionWrapper id="intern">
            <SectionContainer>
                <span>Magang Hari Ini</span>
                <JobGrid>
                    {filtered.map((item, index) => <JobCard key={index} href={item.link} target="_blank">
                        <JobContainerFooter>
                            <JobFooterFlex>
                                <JobFooter>
                                    <img src={item.company_image_url} alt={`image_${item.company}`} />
                                    <JobFooterDetail>
                                        <h1>{item.company}</h1>
                                        <span>{item.location}</span>
                                    </JobFooterDetail>
                                </JobFooter>
                                <Md.MdOutlineArrowOutward size={20} />
                            </JobFooterFlex>
                        </JobContainerFooter>
                        <JobContainer>
                            <JobContainerTop>
                                <JobContainerRole>
                                    <h1>{item.role}</h1>
                                    <span><Go.GoClock /> {timeSince(new Date(item.created_at))}</span>
                                </JobContainerRole>
                                <JobDetailFlex>
                                    <span>{item.type}</span>
                                    <div>
                                        <Ci.CiLocationOn />
                                        <h2>{item.location}</h2>
                                    </div>
                                </JobDetailFlex>
                            </JobContainerTop>
                        </JobContainer>
                    </JobCard>)}
                </JobGrid>
                {filtered.length < data.length ? <JobLoadMore>
                    <LoadMoreButton onClick={onLoadMore}>
                        <span>Load More</span>
                    </LoadMoreButton>
                </JobLoadMore> : null}
            </SectionContainer>
        </SectionWrapper>
    )
}