import React from "react";
import { useParams } from "react-router-dom";
import { profiles } from "../personality/final/data";
import { CommonAppsWrapper } from "../personality/style";
import { GeneratedContent, GeneratedContentHead, GeneratedContentLeft, GeneratedContentRight, GeneratedHeader, GeneratedWrapper } from "./style";

export const Generated = () => {
    const { personality } = useParams();
    const result = profiles[personality];
    return (
        <CommonAppsWrapper>
            <GeneratedWrapper bgColor={result.color}>
                <GeneratedContent>
                    <GeneratedHeader>
                        <h1>by intrn.id</h1>
                        <GeneratedContentHead>
                            <GeneratedContentLeft>
                                <h1>Kamu adalah ...</h1>
                                <h2>{personality}</h2>
                                <p>{result.description}</p>
                            </GeneratedContentLeft>
                            <GeneratedContentRight>
                                <img src={result.image_url} alt={personality} />
                            </GeneratedContentRight>
                        </GeneratedContentHead>
                    </GeneratedHeader>
                </GeneratedContent>
            </GeneratedWrapper>
        </CommonAppsWrapper>
    )
}