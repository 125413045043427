import { useContext, useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
import * as Fa from 'react-icons/fa';
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../component/context";
import { questions } from "./data";
import { Started } from "./started";
import { AppsWrapper, BottomSheet, BottomSheetContainerQuestion, BottomSheetHeader, ButtonFlex, QuestionButton, QuestionContainer, SectionContainer, SectionWrapper } from "./style";

const SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';

export const PersonalityPage = () => {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Personality" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigation = useNavigate();
    const [index, setIndex] = useState(0);
    const counts = { E: 0, I: 0, S: 0, N: 0, T: 0, F: 0, J: 0, P: 0 };
    const [answer, setAnswer] = useState([]);
    const [ready, setReady] = useState(false);
    const { name, setPersonalityResult } = useContext(DataContext);

    const calculateCount = () => {
        let personalityType = '';

        answer.forEach(ans => {
            counts[ans]++;
        });

        personalityType += counts.E >= counts.I ? 'E' : 'I';
        personalityType += counts.S >= counts.N ? 'S' : 'N';
        personalityType += counts.T >= counts.F ? 'T' : 'F';
        personalityType += counts.J >= counts.P ? 'J' : 'P';
        return personalityType
    }

    const saveAnswer = (e) => {
        answer.push(e);
        setAnswer(answer);

        if (index === questions.length - 1) {
            let personality = calculateCount();
            setPersonalityResult(counts);
            sendSlackNotification(`*New Personality Test Result*\n\n*Name*\n${name}\n*Personality*\n${personality}`)
            navigation(`/personality/result/${personality}`);
        } else {
            setIndex(index + 1);
        }
    }

    const sendSlackNotification = async (message) => {
        try {
            const response = await fetch(SLACK_WEBHOOK_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify({
                    text: message,
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };

    if (ready) {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>INTRN | Personality Test</title>
                    <link rel="canonical" href="http://intrn.bykace.com" />
                </Helmet>
                <AppsWrapper>
                    <SectionWrapper>
                        <SectionContainer>
                            <QuestionContainer>
                                <span>@intrn.id</span>
                                <h1>{name}</h1>
                            </QuestionContainer>
                        </SectionContainer>
                    </SectionWrapper>
                    <BottomSheet>
                        <BottomSheetContainerQuestion>
                            <BottomSheetHeader>
                                <Fa.FaArrowLeft onClick={() => {
                                    if (index === 0) {
                                        setReady(false);
                                    } else {
                                        setIndex(index - 1);
                                        answer.pop();
                                    }
                                }} />
                                <p><span>PERTANYAAN {index + 1} </span>/ {questions.length}</p>
                            </BottomSheetHeader>

                            <h1>{questions[index].question}</h1>
                            <ButtonFlex>
                                {questions[index].options.map((item, index) =>
                                    <QuestionButton key={index} onClick={() => saveAnswer(item.value)}>
                                        {item.answer}
                                    </QuestionButton>)}
                            </ButtonFlex>
                        </BottomSheetContainerQuestion>
                    </BottomSheet>
                </AppsWrapper>
            </>
        )
    } else {
        return (<Started setReady={setReady} />)
    }


}