import styled from "styled-components";

export const BlogsGrid = styled.div`
    display: grid;
    gap: 2rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const BlogCard = styled.div`
    border: 1px solid rgba(0,0,0,.06);
    border-radius: 8px;
    cursor: pointer;
    transition: .5s ease-in;

    & > img {
        width: 100%;
    }

    &:hover {
        transform: scale(1.03);
    }
`;

export const BlogMainWrapper = styled.div`
    padding: 1rem 1.5rem 1.25rem;
`;

export const BlogMainDetail = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    gap: 1rem;

    & > h1 {
        font-size: clamp(1.7rem,1.8277vw + 1.2rem,1rem);
        /* max-width: 600px; */
    }

    & > p {
        font-size: 16px;
        line-height: 1.5;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: 3rem;
    }
`