import styled from "styled-components";

export const JobDetailHeader = styled.div`
    padding: 0 1rem;
`;

export const JobDetailContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const JobDetailLeft = styled.div`
`;

export const JobDetailMain = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    gap: 1rem;

    & > h1 {
        font-size: clamp(2.125rem,1.8277vw + 1.6795rem,3rem);
        /* max-width: 600px; */
    }

    & > p {
        font-size: 16px;
        line-height: 1.5;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: 3rem;
    }
`;

export const JobDetailBody = styled.div`
    margin-top: 4rem;

    & > img {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0 0 #0000 ,0 0 #0000, 4px 4px 0 0 #000!important;
        border: 2px solid #000;
    }
`;

export const JobDetailContent = styled.div`
    margin: 2rem auto;
    max-inline-size: min(100% - 2*clamp(1.5rem,5.2219vw + 0.1272rem,4rem),732px);

    & > p {
        line-height: 1.5;
        font-size: clamp(1.0625rem,0.1305vw + 1.0307rem,1.125rem);
        margin-block-start: clamp(1.75rem,0.5222vw + 1.6227rem,2rem);
    }

    & > h1 {
        font-size: 1.25rem;
        margin-block-start: clamp(2.5rem,1.0444vw + 2.2454rem,3rem);
    }

    & > ul {
        & > li {
            line-height: 1.5;
            font-size: clamp(1.0625rem,0.1305vw + 1.0307rem,1.125rem);
            margin-block-start: clamp(1.75rem,0.5222vw + 1.6227rem,2rem);
        }
    }

    @media screen and (min-width: 1024px) {
        max-inline-size: min(100% - 2*clamp(1.5rem,5.2219vw + 0.2272rem,4rem),732px);

        & > h1 {
            font-size: clamp(1.375rem,0.7833vw + 1.1841rem,1.2rem);
        }
    }
`;