import styled from "styled-components";

export const HeadlineFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: 3rem;
    }
`;

export const HeadlineHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 12px;

    & > h2 {
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 3px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const HeadlineMainWrapper = styled.div`
    flex: 3;
`;

export const HeadlineMain = styled.a`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-decoration: none;

    & > img {
        width: 100%;
        border-radius: 8px;
        box-shadow: 0 0 #0000 ,0 0 #0000, 4px 4px 0 0 #000!important;
        border: 2px solid #000;
    }

    & > h1 {
        font-size: clamp(1.125rem,1.2821vw + 0.3237rem,1.25rem);
        max-width: 600px;
        cursor: pointer;
        font-weight: 700;

        &:hover{
            text-decoration: underline;
        }
    }

    &:visited {
        color: #000;
        text-decoration: none;
    }

    @media screen and (min-width: 1024px) {
         & > h1 {
            font-size: clamp(1.5rem,2.518vw + 0.5558rem,2.375rem);
         }
    }
`;

export const HeadlineTag = styled.div`
    display: flex;
    margin: 1.5rem 0 8px;
    align-items: center;
    gap: .8rem;

    & > span {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
    }

    &::after{
            background-color: rgba(0,0,0,.9);
            block-size: 1px;
            inline-size: 2rem;
            content: "";
        }
`;

export const HeadlineFooter = styled.div`
    display: flex;
    margin-top: 1rem;
    gap: 8px;

    & > span {
        display: flex;
        align-items: center;
        gap: 8px;

        & > span {
            font-weight: 500;
            color: rgba(0,0,0,.8);
            font-size: 13px;
        }
    }

    & > span:not(:last-child)::after{
        background-color: rgba(0,0,0,.12);
        block-size: 1px;
        content: "";
        inline-size: .75rem;
    }
`;

export const HeadlineSideWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const HeadlineSide = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & > img {
        width: 100%;
        border-radius: 8px;
        box-shadow: 0 0 #0000 ,0 0 #0000, 2px 2px 0 0 #000!important;
        border: 1px solid #000;
    }

    & > h1 {
        font-size: clamp(1.125rem,1.2821vw + 0.3237rem,1.25rem);
        max-width: 600px;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
`;