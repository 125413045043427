import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { LiaStarOfLifeSolid } from 'react-icons/lia';
import { useLocation, useParams } from "react-router-dom";
import banner from '../../../assets/image/banner.png';
import { ModalComponent } from "../../../component/modal";
import { CommonAppsWrapper, SectionContainer, SectionWrapper } from "../style";
import { LongDesc, ProfileWithDesc, ProfilesWithCompanies, ProfilesWithSuggestions, SideHustleSuggestion, profiles } from "./data";
import { CardContent, FinalCard, FinalWrapper, ModalContent, ModalWrapper } from "./style";

export const Final = () => {
    const { personality } = useParams();
    const [isOpen, setIsOpen] = useState(true);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: `Final ${personality}` });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (profiles[personality.toUpperCase()] === undefined) {
        return <h1>Not Found</h1>
    }
    const result = profiles[personality.toUpperCase()];
    const shortDesc = ProfileWithDesc[personality.toUpperCase()];
    const longDesc = LongDesc[personality.toUpperCase()];
    const sideHustle = SideHustleSuggestion[personality.toUpperCase()];
    const suggestion = ProfilesWithSuggestions[personality.toUpperCase()];
    const companies = ProfilesWithCompanies[personality.toUpperCase()];
    // const { personalityResult } = useContext(DataContext);

    return (
        <CommonAppsWrapper>
            <SectionWrapper style={{ padding: "0" }}>
                <SectionContainer>
                    <FinalWrapper>
                        <FinalCard style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                            <a href="https://instagram.com/intrn.id">@intrn.id</a>
                            <LiaStarOfLifeSolid size={35} color={result.color} />
                            <img src={result.image_url} alt={personality} />
                            <h1>{personality.toUpperCase()}</h1>
                            <h2>{result.name}</h2>
                            <p>{shortDesc.short_description}</p>
                            {/* <StartedButton style={{ marginTop: "2rem" }}>
                                Share Hasilnya
                            </StartedButton> */}
                        </FinalCard>
                        <FinalCard>
                            <CardContent>
                                <h1>{result.name} <br /> Personality</h1>
                                <h2>Introduction</h2>
                                <p>{longDesc.introduction}</p>
                                <h2>Kekuatan</h2>
                                {result.strengths.map((item, index) => <span key={index}>{item}{index === result.strengths.length - 1 ? "" : ","} </span>)}
                                <h2>Kelemahan</h2>
                                {result.weaknesses.map((item, index) => <span key={index}>{item}{index === result.weaknesses.length - 1 ? "" : ","} </span>)}
                                <h2>Olahraga yang Cocok</h2>
                                {sideHustle.suitable_sports.map((item, index) => <span key={index}>{item}{index === sideHustle.suitable_sports.length - 1 ? "" : ","} </span>)}
                                <h2>Sebagai Teman, {personality} itu</h2>
                                <p>{sideHustle.friendship_dynamics}</p>
                                <h2>Tokoh Penting</h2>
                                {result.famous_persons.map((item, index) => <span key={index}>{item}{index === result.famous_persons.length - 1 ? "" : ","} </span>)}
                                <h2>Cocok sama</h2>
                                {result.compatible_with.map((item, index) => <span key={index}>{item}{index === result.compatible_with.length - 1 ? "" : ","} </span>)}
                                <h2>Kurang cocok sama</h2>
                                {result.not_compatible_with.map((item, index) => <span key={index}>{item}{index === result.not_compatible_with.length - 1 ? "" : ","} </span>)}
                            </CardContent>
                        </FinalCard>
                        <FinalCard>
                            <CardContent>
                                <h1>Persiapan <br /> Kerja</h1>
                                <h2>Tipe</h2>
                                <span>{companies.name}</span>
                                <h2>Kerjaan yang Cocok</h2>
                                {result.suitable_jobs.map((item, index) => <span key={index}>{item}{index === result.suitable_jobs.length - 1 ? "" : ","} </span>)}
                                <h2>Side Hustle/Sampingan yang Cocok</h2>
                                {sideHustle.suitable_side_hustle_job.map((item, index) => <span key={index}>{item}{index === sideHustle.suitable_side_hustle_job.length - 1 ? "" : ","} </span>)}
                                <h2>Perusahaan yang Cocok</h2>
                                {companies.suitable_companies.map((item, index) => <span key={index}>{item}{index === companies.suitable_companies.length - 1 ? "" : ","} </span>)}
                                <h2>Rate Workaholic</h2>
                                <span>{sideHustle.workaholic_meter}</span>
                                <h2>Ketika di bawah Tekanan</h2>
                                <p>{suggestion.when_under_pressure_on_work}</p>
                                <h2>Ketika bosen sama Kerjaan</h2>
                                <p>{suggestion.when_bored_on_work}</p>
                                <h2>Musik yang cocok kalo lagi capek</h2>
                                <p>{suggestion.kind_of_music_to_hear_when_tired}</p>
                                <h2>Comfort Food</h2>
                                <p>{suggestion.comfort_food_to_eat}</p>
                                <h2>Kalo mau minta kenaikan gaji</h2>
                                <p>{suggestion.what_to_do_to_ask_salary_increment_to_boss}</p>
                            </CardContent>
                        </FinalCard>
                        <FinalCard>
                            <CardContent>
                                <h1>Saran <br /> buat {personality.toUpperCase()}</h1>
                                <h2>Saran</h2>
                                <p>{sideHustle.suggestion}</p>
                                <h2>Yang harus diperhatikan</h2>
                                <p>{sideHustle.beware}</p>
                                <h2>Penyemangatmu</h2>
                                <p>{sideHustle.words_of_encouragement}</p>
                                <span>Follow us <a href="https://instagram.com/intrn.id">@intrn.id</a></span>
                            </CardContent>
                        </FinalCard>
                        {/* <ContentWrapper>
                            <CardHeader color={result.color}>
                                <PiSuitcase /> Pekerjaan
                            </CardHeader>
                            <CardDetail>
                                <CardContent>
                                    {result.suitable_jobs.map((item, index) => <span key={index}>{item}{index === result.suitable_jobs.length - 1 ? "" : ","} </span>)}
                                </CardContent>
                            </CardDetail>
                        </ContentWrapper>
                        <ContentWrapper>
                            <CardHeader color={result.color}>
                                <LuThumbsUp /> Kekuatan
                            </CardHeader>
                            <CardDetail>
                                <CardContent>
                                    {result.strengths.map((item, index) => <span key={index}>{item}{index === result.strengths.length - 1 ? "" : ","} </span>)}
                                </CardContent>
                            </CardDetail>
                        </ContentWrapper>
                        <ContentWrapper>
                            <CardHeader color={result.color}>
                                <LuThumbsDown /> Kelemahan
                            </CardHeader>
                            <CardDetail>
                                <CardContent>
                                    {result.weaknesses.map((item, index) => <span key={index}>{item}{index === result.weaknesses.length - 1 ? "" : ","} </span>)}
                                </CardContent>
                            </CardDetail>
                        </ContentWrapper>
                        <ContentWrapper>
                            <CardHeader color={result.color}>
                                <FaRegGrinHearts /> Tokoh Penting
                            </CardHeader>
                            <CardDetail>
                                <CardContent>
                                    {result.famous_persons.map((item, index) => <span key={index}>{item}{index === result.famous_persons.length - 1 ? "" : ","} </span>)}
                                </CardContent>
                            </CardDetail>
                        </ContentWrapper>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <ContentWrapper>
                                <CardHeader color={result.color}>
                                    <IoHeartOutline /> Cocok
                                </CardHeader>
                                <CardDetail>
                                    <CardContent>
                                        {result.compatible_with.map((item, index) => <span key={index}>{item}{index === result.compatible_with.length - 1 ? "" : ","} </span>)}
                                    </CardContent>
                                </CardDetail>
                            </ContentWrapper>
                            <ContentWrapper>
                                <CardHeader color={result.color}>
                                    <IoHeartDislikeOutline /> Kureng
                                </CardHeader>
                                <CardDetail>
                                    <CardContent>
                                        {result.not_compatible_with.map((item, index) => <span key={index}>{item}{index === result.not_compatible_with.length - 1 ? "" : ","} </span>)}
                                    </CardContent>
                                </CardDetail>
                            </ContentWrapper>
                        </div> */}
                    </FinalWrapper>
                </SectionContainer>
            </SectionWrapper>
            <ModalComponent
                show={isOpen}
                onHide={() => {
                    setIsOpen(false)
                }}
                body={<ModalWrapper>
                    <img src={banner} alt="banner" />
                    <ModalContent>
                        <h1>Support kami!</h1>
                        <p>Terimakasih sudah menggunakan fitur Personality Test by @intrn.id.</p>
                        <p>Support kami dengan capture hasil testnya, share di Instagram Story kamu dan jangan lupa tag @intrn.id.</p>
                        <p>Bagikan juga link testnya biar teman-teman kamu juga bisa mengenal kepribadian mereka.</p>
                        <p style={{ margin: "0" }}>Jika ada masukan, temukan kami di <a href="https://instagram.com/intrn.id">@intrn.id</a></p>
                    </ModalContent>
                </ModalWrapper>}
            />
        </CommonAppsWrapper>
    )
}