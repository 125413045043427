import styled from "styled-components";

export const JobGrid = styled.div`
    display: grid;
    gap: 2rem;
    margin-top: 2rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

export const JobCard = styled.a`
    box-shadow: 0 0 #0000 ,0 0 #0000, 4px 4px 0 0 #000!important;
    border: 1px solid #000;
    cursor: pointer;
    transition: .5s ease;
    text-decoration: none;
    color: #000;
    background-color: #fff;

    &:hover {
        transform: scale(1.03);
    }
`;

export const JobContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const JobContainerTop = styled.div`
    padding: 1rem;
`;

export const JobContainerRole = styled.div`
    height: 65px;

    & > h1 {
        font-size: 18px;
        margin-bottom: 4px;
    }

    & > span {
        font-size: 14px;
        display: flex;
        gap: 4px;
    }
`;

export const JobDetailFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    & > span {
        background-color: #fff3d4;
        color: #fab601;
        padding: 6px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
    }

    & > div {
        & > h2 {
            font-family: 'Overpass', sans-serif;
            font-size: 15px;
            transform: translateY(2px);
        }
        gap: 4px;
        display: flex;
        align-items: center;
    }
`;

export const JobContainerFooter = styled.div`
    padding: 1rem;
    border-bottom: 1px solid #eee;
`;

export const JobFooterFlex = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const JobFooter = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    & > img {
        width: 50px;
    }
`;

export const JobFooterDetail = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        /* font-family: 'Overpass', sans-serif; */
        font-size: 18px;
    }

    & > span {
        font-size: 14px;
    }
`;

export const JobLoadMore = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 4rem;
`;

export const LoadMoreButton = styled.button`
    border: 1px solid rgba(0,0,0,.12);
    color: rgba(0,0,0,.9);
    padding: 10px 1rem;
    outline: none;
    background-color: transparent;
    border-radius: 20px;

    & > span {
        font-size: 15px;
    }
`;