import React from "react";
import * as Md from 'react-icons/md';
import { SectionContainer, SectionWrapper } from "../../pages/style";
import { timeSince } from "../../utils";
import { KisahIntern } from "./data";
import { HeadlineFlex, HeadlineFooter, HeadlineHeader, HeadlineMain, HeadlineMainWrapper, HeadlineSide, HeadlineSideWrapper, HeadlineTag } from "./style";

export const Headline = () => {
    return (
        <SectionWrapper style={{ paddingTop: "4rem" }} id="story">
            <SectionContainer>
                <HeadlineHeader>
                    <h2 onClick={() => window.location = "/blogs"}>
                        Read All <Md.MdOutlineArrowOutward size={25} />
                    </h2>
                </HeadlineHeader>
                <HeadlineFlex>
                    <HeadlineMainWrapper>
                        <HeadlineMain href={`/blog/${KisahIntern[0].uuid}`} target="_blank" rel="noreferrer">
                            <img src={KisahIntern[0].image_url} alt="Kisah Intern Main" width="100%" />
                            <HeadlineTag>
                                <span>{KisahIntern[0].category}</span>
                            </HeadlineTag>
                            <h1>{KisahIntern[0].title}</h1>
                            <HeadlineFooter>
                                <span>
                                    <span>{timeSince(new Date(KisahIntern[0].created_at))}</span>
                                </span>
                                <span>
                                    <span>{KisahIntern[0].read} read</span>
                                </span>
                            </HeadlineFooter>
                        </HeadlineMain>
                    </HeadlineMainWrapper>
                    <HeadlineSideWrapper>
                        <HeadlineSide onClick={() => window.location = `/blog/${KisahIntern[1].uuid}`}>
                            <img src={KisahIntern[1].image_url} alt="Kisah Intern 1" width="100%" />
                            <HeadlineTag>
                                <span>{KisahIntern[1].category}</span>
                            </HeadlineTag>
                            <h1>{KisahIntern[1].title}</h1>
                            <HeadlineFooter>
                                <span>
                                    <span>{timeSince(new Date(KisahIntern[1].created_at))}</span>
                                </span>
                                <span>
                                    <span>{KisahIntern[0].read} read</span>
                                </span>
                            </HeadlineFooter>
                        </HeadlineSide>

                        <HeadlineSide onClick={() => window.location = `/blog/${KisahIntern[2].uuid}`}>
                            <img src={KisahIntern[2].image_url} alt="Kisah Intern 2" width="100%" />
                            <HeadlineTag>
                                <span>{KisahIntern[2].category}</span>
                            </HeadlineTag>
                            <h1>{KisahIntern[2].title}</h1>
                            <HeadlineFooter>
                                <span>
                                    <span>{timeSince(new Date(KisahIntern[2].created_at))}</span>
                                </span>
                                <span>
                                    <span>{KisahIntern[2].read} read</span>
                                </span>
                            </HeadlineFooter>
                        </HeadlineSide>
                    </HeadlineSideWrapper>
                </HeadlineFlex>
                {/* <JobLoadMore>
                    <LoadMoreButton>
                        <span>Load More</span>
                    </LoadMoreButton>
                </JobLoadMore> */}
            </SectionContainer>
        </SectionWrapper>
    )
}