import React, { useState } from "react";
import { Navbar } from "../../component/navbar";
import { KisahIntern } from "../../section/headline/data";
import { HeadlineFooter, HeadlineTag } from "../../section/headline/style";
import { timeSince } from "../../utils";
import { SectionContainer, SectionWrapper } from "../style";
import { BlogCard, BlogMainDetail, BlogMainWrapper, BlogsGrid } from "./style";

export const Blogs = () => {
    const [data] = useState(KisahIntern);
    return (
        <>
            <Navbar />
            <SectionWrapper style={{ paddingTop: "3rem" }}>
                <SectionContainer>
                    <BlogsGrid>
                        {data.map((item, index) => <BlogCard key={index} onClick={() => window.location = `/blog/${item.uuid}`}>
                            <img src={item.image_url} alt={`Image_${index}`} />
                            <BlogMainWrapper>
                                <HeadlineTag style={{ marginTop: "8px" }}>
                                    <span>{item['category']}</span>
                                </HeadlineTag>
                                <BlogMainDetail>
                                    <h1>{item.title}</h1>
                                    <p>{item.short}</p>
                                </BlogMainDetail>
                                <HeadlineFooter style={{ marginTop: "2rem" }}>
                                    <span>
                                        <span>{item.created_at && timeSince(new Date(item.created_at))}</span>
                                    </span>
                                    <span>
                                        <span>{item.read} read</span>
                                    </span>
                                </HeadlineFooter>
                            </BlogMainWrapper>
                        </BlogCard>)}
                    </BlogsGrid>
                </SectionContainer>
            </SectionWrapper>
        </>
    )
}