export const questions = [
    {
        "question": "Kamu merasa lebih semangat kalau ada di lingkungan yang rame?",
        "type": "E-I",
        "options": [
            {
                "answer": "Setuju",
                "value": "E"
            },
            {
                "answer": "Nggak Setuju",
                "value": "I"
            }
        ]
    },
    {
        "question": "Kamu lebih suka kerja sendirian daripada kerja bareng tim?",
        "type": "E-I",
        "options": [
            {
                "answer": "Setuju",
                "value": "I"
            },
            {
                "answer": "Nggak Setuju",
                "value": "E"
            }
        ]
    },
    {
        "question": "Kamu sering mulai percakapan sama orang baru?",
        "type": "E-I",
        "options": [
            {
                "answer": "Setuju",
                "value": "E"
            },
            {
                "answer": "Nggak Setuju",
                "value": "I"
            }
        ]
    },
    {
        "question": "Kamu biasanya capek kalau lama-lama ngumpul sama banyak orang?",
        "type": "E-I",
        "options": [
            {
                "answer": "Setuju",
                "value": "I"
            },
            {
                "answer": "Nggak Setuju",
                "value": "E"
            }
        ]
    },
    {
        "question": "Kamu lebih suka habisin weekend dengan bersosialisasi daripada sendirian di rumah?",
        "type": "E-I",
        "options": [
            {
                "answer": "Setuju",
                "value": "E"
            },
            {
                "answer": "Nggak Setuju",
                "value": "I"
            }
        ]
    },
    {
        "question": "Kamu lebih fokus pada fakta dan detail daripada konsep dan ide?",
        "type": "S-N",
        "options": [
            {
                "answer": "Setuju",
                "value": "S"
            },
            {
                "answer": "Nggak Setuju",
                "value": "N"
            }
        ]
    },
    {
        "question": "Kamu sering pakai intuisi buat ambil keputusan?",
        "type": "S-N",
        "options": [
            {
                "answer": "Setuju",
                "value": "N"
            },
            {
                "answer": "Nggak Setuju",
                "value": "S"
            }
        ]
    },
    {
        "question": "Kamu lebih suka mikirin kemungkinan masa depan daripada apa yang nyata sekarang?",
        "type": "S-N",
        "options": [
            {
                "answer": "Setuju",
                "value": "N"
            },
            {
                "answer": "Nggak Setuju",
                "value": "S"
            }
        ]
    },
    {
        "question": "Kamu lebih nyaman ikutin rutinitas yang udah dikenal daripada coba hal-hal baru?",
        "type": "S-N",
        "options": [
            {
                "answer": "Setuju",
                "value": "S"
            },
            {
                "answer": "Nggak Setuju",
                "value": "N"
            }
        ]
    },
    {
        "question": "Kamu lebih cenderung perhatiin detail kecil daripada gambaran besar?",
        "type": "S-N",
        "options": [
            {
                "answer": "Setuju",
                "value": "S"
            },
            {
                "answer": "Nggak Setuju",
                "value": "N"
            }
        ]
    },
    {
        "question": "Kamu lebih cenderung ambil keputusan berdasarkan logika daripada emosi?",
        "type": "T-F",
        "options": [
            {
                "answer": "Setuju",
                "value": "T"
            },
            {
                "answer": "Nggak Setuju",
                "value": "F"
            }
        ]
    },
    {
        "question": "Kamu sering mikirin perasaan orang lain saat ambil keputusan?",
        "type": "T-F",
        "options": [
            {
                "answer": "Setuju",
                "value": "F"
            },
            {
                "answer": "Nggak Setuju",
                "value": "T"
            }
        ]
    },
    {
        "question": "Kamu lebih suka ngadepin konflik langsung daripada ngindarin?",
        "type": "T-F",
        "options": [
            {
                "answer": "Setuju",
                "value": "T"
            },
            {
                "answer": "Nggak Setuju",
                "value": "F"
            }
        ]
    },
    {
        "question": "Kamu cenderung lebih rasional daripada empatik?",
        "type": "T-F",
        "options": [
            {
                "answer": "Setuju",
                "value": "T"
            },
            {
                "answer": "Nggak Setuju",
                "value": "F"
            }
        ]
    },
    {
        "question": "Kamu lebih cenderung mudah memahami perasaan orang lain?",
        "type": "T-F",
        "options": [
            {
                "answer": "Setuju",
                "value": "F"
            },
            {
                "answer": "Nggak Setuju",
                "value": "T"
            }
        ]
    },
    {
        "question": "Kamu sering ngerencanain jauh-jauh hari daripada spontan?",
        "type": "J-P",
        "options": [
            {
                "answer": "Setuju",
                "value": "J"
            },
            {
                "answer": "Nggak Setuju",
                "value": "P"
            }
        ]
    },
    {
        "question": "Kamu lebih suka segala sesuatunya tetap terbuka dan fleksibel daripada bikin rencana tetap?",
        "type": "J-P",
        "options": [
            {
                "answer": "Setuju",
                "value": "P"
            },
            {
                "answer": "Nggak Setuju",
                "value": "J"
            }
        ]
    },
    {
        "question": "Kamu nyaman dengan jadwal yang terstruktur?",
        "type": "J-P",
        "options": [
            {
                "answer": "Setuju",
                "value": "J"
            },
            {
                "answer": "Nggak Setuju",
                "value": "P"
            }
        ]
    },
    {
        "question": "Kamu cenderung tunda pekerjaan sampai mendekati batas waktu?",
        "type": "J-P",
        "options": [
            {
                "answer": "Setuju",
                "value": "P"
            },
            {
                "answer": "Nggak Setuju",
                "value": "J"
            }
        ]
    },
    {
        "question": "Kamu lebih nyaman dengan keputusan yang udah ditentukan daripada yang bisa berubah?",
        "type": "J-P",
        "options": [
            {
                "answer": "Setuju",
                "value": "J"
            },
            {
                "answer": "Nggak Setuju",
                "value": "P"
            }
        ]
    }
]
