import React, { useRef, useState } from "react";
import { FaTrash } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';
import img from '../../assets/image/bg.png';
import { Navbar } from "../../component/navbar";
import { SectionContainer } from "../style";
import { AddMore, CVEditor, CVEditorContainer, CVEditorSection, CVEditorSectionFlex, CVFlex, CVFormContainer, CVFormWrapper, CVPageEditor, CVPageWrapper, CVSectionWrapper, CanvasBody, CanvasBodyDetail, CanvasBodyHeader, CanvasDetailFlex, CanvasDrawer, CanvasHeader, CanvasSection, EmploymentBody, EmploymentContainer, EmploymentHeader } from "./style";

export const CVPage = () => {
    const [data, setData] = useState({
        role: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        city: '',
        summary: ''
    });

    const employmentKey = useRef(0);
    const [employments, setEmployments] = useState({});
    const [educations, setEducations] = useState({});

    const onChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setEducations({});
    };

    const onAddEmployment = () => {
        employmentKey.current++;
        let tempObj = { ...employments };
        tempObj[`E-${employmentKey.current}`] = {
            title: "",
            employer: "",
            start_date: "",
            end_date: "",
            city: "",
            description: ""
        }
        setEmployments(tempObj);
    };

    const onChangeEmployment = ({ currentTarget: input }) => {
        const id = input.id;
        const obj = { ...employments };
        const currObj = obj[id];
        console.log(currObj);
        currObj[input.name] = input.value;
        obj[id] = currObj;
        setEmployments(obj);
    };

    const EmploymentWrapper = (empKey, data) => {
        return (
            <EmploymentContainer>
                <EmploymentHeader>
                    {data.title === "" && data.employer === "" ? "Not Specified" : <>{data.title} {data.employer === "" ? "" : `at ${data.employer}`}</>}
                    <FaTrash onClick={() => {
                        let newEmployments = Object.keys(employments).filter(objKey => objKey !== `E-${empKey}`).reduce((newObj, key) => {
                            newObj[key] = employments[key];
                            return newObj;
                        }, {}
                        )
                        --employmentKey.current;
                        setEmployments(newEmployments);
                    }} />
                </EmploymentHeader>
                <EmploymentBody>
                    <CVEditorSectionFlex style={{ marginTop: "0" }}>
                        <CVFormWrapper>
                            <label>Title</label>
                            <input
                                name="title"
                                placeholder="Contoh: Software Engineer"
                                {...(data['title'] === "" ? { value: "" } : { value: data['title'] })}
                                onChange={onChangeEmployment}
                                id={`E-${empKey}`}
                            />
                        </CVFormWrapper>
                        <CVFormWrapper>
                            <label>Perusahaan</label>
                            <input
                                name="employer"
                                placeholder="Contoh: INTRN"
                                {...(data['employer'] === "" ? { value: "" } : { value: data['employer'] })}
                                onChange={onChangeEmployment}
                                id={`E-${empKey}`}
                            />
                        </CVFormWrapper>
                    </CVEditorSectionFlex>
                    <CVEditorSectionFlex>
                        <CVFlex>
                            <CVFormWrapper style={{ flex: "1" }}>
                                <label>Start Date</label>
                                <input
                                    name="start_date"
                                    {...(data['start_date'] === "" ? { value: "" } : { value: data['start_date'] })}
                                    onChange={onChangeEmployment}
                                    id={`E-${empKey}`}
                                    type="date"
                                />
                            </CVFormWrapper>
                            <CVFormWrapper style={{ flex: "1" }}>
                                <label>End Date</label>
                                <input
                                    name="end_date"
                                    {...(data['end_date'] === "" ? { value: "" } : { value: data['end_date'] })}
                                    onChange={onChangeEmployment}
                                    id={`E-${empKey}`}
                                    type="date"
                                />
                            </CVFormWrapper>
                        </CVFlex>
                        <CVFormWrapper>
                            <label>City</label>
                            <input
                                name="city"
                                placeholder="Contoh: Jakarta"
                                {...(data['city'] === "" ? { value: "" } : { value: data['city'] })}
                                onChange={onChangeEmployment}
                                id={`E-${empKey}`}
                            />
                        </CVFormWrapper>
                    </CVEditorSectionFlex>
                    <CVFormContainer>
                        <textarea
                            name="description"
                            placeholder="Contoh: - Do research that excalate company's revenue by 80%"
                            {...(data['description'] === "" ? { value: "" } : { value: data['description'] })}
                            onChange={onChangeEmployment}
                            id={`E-${empKey}`}
                        />
                    </CVFormContainer>
                </EmploymentBody>
            </EmploymentContainer>
        );
    };

    return (
        <>
            <Navbar />
            <SectionContainer>
                <CVPageWrapper>
                    <CVPageEditor>
                        <CVSectionWrapper>
                            <h1>Personal Details</h1>
                            <CVEditorSection>
                                <CVFormWrapper>
                                    <label>Role yang diinginkan</label>
                                    <input
                                        name="role"
                                        placeholder="Contoh: Software Engineer"
                                        value={data.role}
                                        onChange={onChange}
                                    />
                                </CVFormWrapper>
                                <CVFormWrapper>
                                    <label>First Name</label>
                                    <input
                                        name="first_name"
                                        placeholder="Contoh: Kevin"
                                        value={data.first_name}
                                        onChange={onChange}
                                    />
                                </CVFormWrapper>
                                <CVFormWrapper>
                                    <label>Last Name</label>
                                    <input
                                        name="last_name"
                                        placeholder="Contoh: Christian"
                                        value={data.last_name}
                                        onChange={onChange}
                                    />
                                </CVFormWrapper>
                                <CVFormWrapper>
                                    <label>Email</label>
                                    <input
                                        name="email"
                                        placeholder="Contoh: kevin@bykace.com"
                                        value={data.email}
                                        onChange={onChange}
                                    />
                                </CVFormWrapper>
                                <CVFormWrapper>
                                    <label>Phone</label>
                                    <input
                                        name="phone"
                                        placeholder="Contoh: 0819919191"
                                        value={data.phone}
                                        onChange={onChange}
                                    />
                                </CVFormWrapper>
                                <CVFormWrapper>
                                    <label>City</label>
                                    <input
                                        name="city"
                                        placeholder="Contoh: Jakarta"
                                        value={data.city}
                                        onChange={onChange}
                                    />
                                </CVFormWrapper>
                            </CVEditorSection>
                        </CVSectionWrapper>
                        <CVSectionWrapper>
                            <h1>Professional Summary</h1>
                            <p>Write 2-4 short & energetic sentences to interest the reader! Mention your role, experience & most importantly - your biggest achievements, best qualities and skills.</p>
                            <CVFormContainer>
                                <textarea
                                    name="summary"
                                    placeholder="Contoh: A dedicated software engineer with 4 years of experience in developing scalable solutions..."
                                    value={data.summary}
                                    onChange={onChange}
                                />
                            </CVFormContainer>
                            <p>Recruiter tip: write 400-600 characters to increase interview chances</p>
                        </CVSectionWrapper>
                        <CVSectionWrapper>
                            <h1>Employment History</h1>
                            <p>Show your relevant experience (last 10 years). Use bullet points to note your achievements, if possible - use numbers/facts (Achieved X, measured by Y, by doing Z).</p>
                            {Object.keys(employments).map((empKey, index) => (
                                EmploymentWrapper(index + 1, employments[empKey])
                            ))}
                            <AddMore onClick={onAddEmployment}>
                                <GoPlus color="rgb(26, 145, 240)" />
                                <span>Add more employment</span>
                            </AddMore>
                        </CVSectionWrapper>

                        <CVSectionWrapper>
                            <h1>Education</h1>
                            <p>Show your relevant experience (last 10 years). Use bullet points to note your achievements, if possible - use numbers/facts (Achieved X, measured by Y, by doing Z).</p>
                            {Object.keys(educations).map((empKey, index) => (
                                EmploymentWrapper(index + 1, educations[empKey])
                            ))}
                            <AddMore onClick={onAddEmployment}>
                                <GoPlus color="rgb(26, 145, 240)" />
                                <span>Add more education</span>
                            </AddMore>
                        </CVSectionWrapper>
                    </CVPageEditor>
                    <CVEditor>
                        <CVEditorContainer>
                            <img src={img} alt="background" />
                            <CanvasDrawer>
                                <CanvasHeader>
                                    <h1>{data.first_name} {data.last_name}</h1>
                                    <h2>{data.role}</h2>
                                    {data.email === "" && data.phone === "" && data.city === "" ? null :
                                        <CanvasDetailFlex>
                                            {data.email} {data.phone !== "" ? `• ${data.phone}` : ""} {data.city !== "" ? `• ${data.city}` : ""}
                                        </CanvasDetailFlex>}
                                    <p>{data.summary}</p>
                                </CanvasHeader>
                                <CanvasBody>
                                    {Object.keys(employments).length === 0 ? null : <CanvasSection>
                                        <h1>Professional Experience</h1>
                                        {Object.keys(employments).map((key, index) => <CanvasBodyDetail>
                                            <CanvasBodyHeader>
                                                <h1>{employments[key].title}{employments[key].employer === "" ? "" : <>{`, ${employments[key].employer}`}</>}</h1>
                                                <h1>{employments[key].start_date === "" ? "" : new Date(employments[key].start_date).toLocaleDateString('en-US', {
                                                    month: "long",
                                                    year: "numeric"
                                                })} - {employments[key].end_date === "" ? "Present" : new Date(employments[key].end_date).toLocaleDateString('en-US', {
                                                    month: "long",
                                                    year: "numeric"
                                                })}</h1>
                                            </CanvasBodyHeader>
                                            <p>{employments[key].description}</p>
                                        </CanvasBodyDetail>)}
                                    </CanvasSection>}
                                </CanvasBody>
                            </CanvasDrawer>
                        </CVEditorContainer>
                    </CVEditor>
                </CVPageWrapper>
            </SectionContainer>
        </>
    );
};
