import styled from "styled-components";

export const CVPageWrapper = styled.div`
    display: flex;
    margin-top: 3rem;
    width: 100%;
`;

export const CVPageEditor = styled.div`
    width: 50%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fff;
`;

export const CVSectionWrapper = styled.div`
    width: 100%;
    margin-bottom: 3rem;

    & > h1 {
        font-size: 21px;
    }

    & > p {
        color: rgb(130, 139, 162);
        margin-top: 3px;
        line-height: 1.3em;
        font-size: 15px;
    }
`;

export const CVEditorSection = styled.div`
    display: flex;
    gap: 1.5rem;
    width: 100%;
    margin-top: 2rem;
    flex-wrap: wrap;
`;

export const CVEditorSectionFlex = styled.div`
    display: flex;
    width: 100%;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const CVFormContainer = styled.div`
    margin-top: 1rem;

    & > textarea {
        background-color: rgb(239, 242, 249);
        border-radius: 3px;
        width: 100%;
        border: 0;
        min-height: 150px;
        padding: 16px;
        font-size: 16px;
        line-height: 1.4em;
        box-shadow: 0 0 #0000 ,0 0 #0000, 2px 2px 0 0 #000!important;
        border: 1px solid #000;
    }
`;

export const CVFormWrapper = styled.div`
    flex: 0 0 calc(50% - 20px);

    & > label {
        display: inline-block;
        color: rgb(130, 139, 162);
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
    }

    & > input {
        background-color: rgb(239, 242, 249);
        padding: 12px 16px;
        box-shadow: 0 0 #0000 ,0 0 #0000, 2px 2px 0 0 #000!important;
        border: 1px solid #000;
        caret-color: rgb(26, 145, 240);
        color: rgb(30, 37, 50);
        outline: none;
        border-radius: 3px;
        width: 100%;
        font-size: 16px;
    }
`;

export const CVFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
    flex: 0 0 calc(50% - 20px);
    width: 50% -20px;
    gap: 4px;
`;

export const AddMore = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
    width: 100%;
    padding: 6px 14px;

    &:hover {
        background-color: rgba(234, 246, 255, 0.5);
    }

    & > span {
        color: rgb(26, 145, 240);
        font-size: 14px;
        font-weight: 600;
    }
`;

export const CVEditor = styled.div`
    margin-top: 2rem;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    flex: 1 1 0%;
    background-color: rgb(101, 110, 131);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CVEditorContainer = styled.div`
    /* inset: 72px 74px 40px; */
    position: relative;

    & > img {
        width: 567px;
        height: 799px;
    }
`;

export const CanvasDrawer = styled.div`
    font-family: 'Inter', sans-serif!important;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 1.5rem;
`;

export const CanvasHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > h1 {
        font-size: 19px;
        font-family: 'Alte', sans-serif!important;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 2px;
    }

    & > h2 {
        font-size: 16px;
        font-family: 'Alte', sans-serif!important;
        font-weight: 600;
    }

    & > p {
        font-size: 12px;
        line-height: 1.4em;
        color: #1e1e1e;
    }
`;

export const CanvasBody = styled.div`
    margin-top: 1rem;
`;

export const CanvasSection = styled.div`
    & > h1 {
        font-size: 15px;
        border-bottom: 1px solid #1e1e1e;
        padding-bottom: 2px;
        font-family: 'Alte', sans-serif!important;
    }
`;

export const CanvasBodyDetail = styled.div`
    margin: 8px 0;

    & > p {
        white-space: pre-wrap;
        font-family: 'Alte', sans-serif!important;
        font-size: 13px;
        margin-top: 2px;
        line-height: 1.4em;

    }
`;

export const CanvasBodyHeader = styled.div`
    display: flex;
    justify-content: space-between;

    & > h1 {
        font-family: 'Alte', sans-serif!important;
        font-size: 13px;
    }
`;

export const CanvasDetailFlex = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    font-family: 'Alte', sans-serif!important;
    margin: 10px 0;
    padding: 4px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    width: 100%;
    font-size: 13px;
    color: #1e1e1e;
`;

export const EmploymentContainer = styled.div`
    border: 1px solid rgb(231, 234, 244);
    background-color: rgb(255, 255, 255);
    margin-top: 10px;
`;

export const EmploymentHeader = styled.div`
    padding: 15px 20px;
    font-weight: 600;

    display: flex;
    justify-content: space-between;

    & > h1 {
        font-size: 16px;
    }
`;

export const EmploymentBody = styled.div`
    padding: 0 20px 10px;
`;