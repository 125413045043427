import styled from "styled-components";

export const SectionWrapper = styled.section`
    max-width: 1080px;
    margin: 1rem auto 3rem;

    @media screen and (min-width: 1024px) {
        margin: 1rem auto 3rem;
    }
`;

export const SectionContainer = styled.div`
    padding: 1rem;

    & > span {
        text-transform: uppercase;
        font-weight: 500;
    }
`;