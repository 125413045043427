export const Internships = [
    {
        company_image_url: "https://media.licdn.com/dms/image/v2/D560BAQF5S91_fst8_g/company-logo_100_100/company-logo_100_100/0/1681376350193?e=1734566400&v=beta&t=VB9ZCIlgSXm70yMkZ7heqMcjP0hztwjg7ttKwiJQ5uw",
        company: "PT Dasha Artha Guna Ciptakarya",
        location: "Jakarta",
        type: "Hybrid",
        role: "Social Media Intern",
        created_at: "2024-09-14T05:28:29.240504Z",
        link: "https://www.linkedin.com/jobs/view/4023360115"
    },
    {
        company_image_url: "https://media.licdn.com/dms/image/v2/D4E0BAQEd1jWez8J3xQ/company-logo_100_100/company-logo_100_100/0/1699798227449?e=1734566400&v=beta&t=_ckaIfOXGqgEQ8BbnDZ15ljRl_bJd0b967z8JgUKTCU",
        company: "Selawe Labs",
        location: "Jakarta",
        type: "On-Site",
        role: "KOL Specialist Intern",
        created_at: "2024-09-14T05:28:29.240504Z",
        link: "https://www.linkedin.com/jobs/view/4023354863"
    },
    {
        company_image_url: "https://media.licdn.com/dms/image/v2/D560BAQFKEAX5qeEgZQ/company-logo_100_100/company-logo_100_100/0/1720524581160/commsult_idn_logo?e=1734566400&v=beta&t=ALo7ah6TC0v2j1gzE2tYPZX1_r6IEv2FSo6EnIMuTCg",
        company: "commsult Indonesia",
        location: "Tangerang",
        type: "Hybrid",
        role: "Graphic Design Intern",
        created_at: "2024-09-14T05:28:29.240504Z",
        link: "https://www.kalibrr.com/c/commsult/jobs/247287/graphic-design-intern"
    },
    {
        company_image_url: "https://media.licdn.com/dms/image/v2/D560BAQF53iiJBMiBbg/company-logo_100_100/company-logo_100_100/0/1725855064947/jacquellebeaute_logo?e=1734566400&v=beta&t=4fug9537QFKsqND3LrY1edjlh580S3qU9szSjJcK30M",
        company: "Jacquelle Beaute",
        location: "Tambora, Jakarta",
        type: "On-Site",
        role: "Social Media & Content Creator Intern",
        created_at: "2024-09-14T05:28:29.240504Z",
        link: "https://www.hiredtoday.com/en/jobs-detail/social-media-content-creator-intern-kamal-muara"
    },
]