import React from "react";
import { Modal } from "react-bootstrap";

export const ModalComponent = ({ body, onHide, show, size = "m" }) => {
  return (
    <Modal
      show={show}
      size={size}
      centered
      onHide={onHide}
      className="modal-border"
    >
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};
