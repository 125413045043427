import styled from "styled-components";


export const FinalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center;
    text-align: center; */
`;

export const FinalCard = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    & > img {
        width: 300px;
        margin: 2rem 0 1rem;
    }

    & > h1 {
        font-size: 21px;
        margin: 1rem 0 0;
        font-family: 'Montagna', sans-serif;
        /* font-family: 'Albert Sans', sans-serif; */
    }

    & > h2 {
        font-size: 28px;
        margin: 8px 0 1rem;
        font-family: 'Montagna', sans-serif;
        /* font-family: 'Albert Sans', sans-serif; */
    }

    & > p {
        font-size: 17px;
        line-height: 1.5;
        color: #636363;
        max-width: 90%;
        text-align: center;
    }

    & > a {
        margin-bottom: 1rem;
        /* font-family: 'Montagna', sans-serif; */
        text-transform: uppercase;
        text-decoration: none;
        color: #636363;
        font-size: 12px;
        font-weight: 700;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const CardContent = styled.div`
    text-align: left;
    align-self: flex-start;

    & > h1 {
        font-size: 32px;
        font-family: 'Montagna', sans-serif;
        margin-bottom: 6px;
        line-height: 1.3;
        text-transform: uppercase;
    }

    & > h2 {
        font-family: 'Montagna', sans-serif;
        font-size: 18px;
        margin: 1.5rem 0 12px;
    }

    & > p {
        font-size: 17px;
        line-height: 1.5;
        color: #636363;
        margin: 8px 0;
        white-space: pre-line;
    }

    & > span {
        color: #636363;
        line-height: 1.5;
        /* margin: 2rem 0 0; */
        /* display: inline-block; */

        & > a {
            color: inherit;

            &:visited {
                color: inherit;
            }
        }
    }
`;

export const CardHeader = styled.div`
    background-color: ${({ color }) => color};
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: #000;
    align-self: flex-start;
    padding: 4px 8px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-top-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 0;
    border-color: #000;
    border-style: solid;
    font-family: 'Albert Sans', sans-serif;
    font-weight: 700;
`;

export const CardDetail = styled.div`
    background-color: #fff;
    box-shadow: 0 0 #0000 ,0 0 #0000, 2px 2px 0 0 #000!important;
    border: 2px solid #000;
    padding: 1rem;
    width: 100%;
    /* border-radius: 20px; */
`;

export const ContentWrapper = styled.div`
    padding: 1rem 1rem 8px;
`

export const ModalWrapper = styled.div`
    & > img {
        width: 100%;
    }
`;

export const ModalContent = styled.div`
    padding: 1rem;

    & > h1 {
        font-family: 'Montagna', sans-serif;
        margin: 1rem 0 6px;
        text-transform: uppercase;
        font-size: 28px;
    }

    & > p {
        & > a {
            color: inherit;
            
        }
    }
`;