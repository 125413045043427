import ReactGA from 'react-ga4';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { DataProvider } from './component/context';
import { Blogs } from './pages/blogs';
import { CVPage } from './pages/cv';
import { JobDetail } from './pages/detail';
import { Generated } from './pages/generated';
import { Home } from './pages/home';
import { PersonalityPage } from './pages/personality';
import { Final } from './pages/personality/final';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/blog/:uuid",
    element: <JobDetail />
  },
  {
    path: "/blogs",
    element: <Blogs />
  },
  {
    path: "/cv",
    element: <CVPage />
  },
  {
    path: "/personality",
    element: <PersonalityPage />
  },
  {
    path: "/personality/result/:personality",
    element: <Final />
  },
  {
    path: "/personality/generated/:personality",
    element: <Generated />
  }
])

function App() {
  return (
    <DataProvider>
      <RouterProvider router={router} />
    </DataProvider>
  );
}

export default App;
