import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LiaStarOfLifeSolid } from 'react-icons/lia';
import { DataContext } from "../../../component/context";
import { AppsWrapper, BottomSheet, BottomSheetContainer, SectionContainer, SectionWrapper, StartedButton } from "../style";

export const Started = ({ setReady }) => {
    const { name, setName } = useContext(DataContext);

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>INTRN | Personality Test</title>
                <link rel="canonical" href="http://intrn.bykace.com" />
            </Helmet>
            <AppsWrapper>
                <SectionWrapper>
                    <SectionContainer>

                    </SectionContainer>
                </SectionWrapper>
                <BottomSheet>
                    <BottomSheetContainer>
                        <LiaStarOfLifeSolid size={35} />
                        <h1>Discover Your Personality.</h1>
                        <p>Penasaran kamu cocoknya kerja sama siapa, dan role apa? Jawab beberapa pertanyaan dari kita dan dapetin insight tentang personalitymu.</p>
                        <input placeholder="Email" type="email" onChange={(e) => setName(e.target.value)} />
                        <StartedButton onClick={() => {
                            if (name === "") {
                                alert("Isi email dulu dongs")
                            } else {
                                setReady(true);
                            }
                        }}>
                            Mulai Sekarang
                        </StartedButton>
                        <span>Follow us on Instagram <a href="https://instagram.com/intrn.id">@intrn.id</a></span>
                    </BottomSheetContainer>
                </BottomSheet>
            </AppsWrapper>
        </>

    )
}