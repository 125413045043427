import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/id.json";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("id-ID");

export function timeSince(date) {
    const response = timeAgo.format(date);
    return response;
}