import styled from "styled-components";

export const GeneratedWrapper = styled.div`
    min-height: 100vh;
    background-color: ${({ bgColor }) => bgColor};
`;

export const GeneratedContent = styled.div`
    padding: 2rem;
`;

export const GeneratedHeader = styled.div`
    & > h1 {
        font-size: 21px;
        color: #fff;
        text-shadow: 2px 2px  black;
        text-align: center;
    }
`;

export const GeneratedContentHead = styled.div`
    display: flex;
    gap: 1rem;
    margin: 2rem 0 ;
`;

export const GeneratedContentLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    & > h1, h2 {
        font-family: 'Albert Sans', sans-serif!important;
    }

    & > h1 {
        font-size: 18px;
    }

    & > h2 {
        text-shadow: 4px 4px #000;
        color: #fff;
        font-size: 52px;
    }

    & > p {
        margin-top: 8px;
        font-size: 15px;
        line-height: 1.5;
    }
`;

export const GeneratedContentRight = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    & > img {
        /* width: 150px; */
        width: 100%;
    }
`;