import React, { useState } from "react";

export const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
    const [name, setName] = useState("");
    const [personalityResult, setPersonalityResult] = useState({ E: 0, I: 0, S: 0, N: 0, T: 0, F: 0, J: 0, P: 0 });

    return (
        <DataContext.Provider
            value={{
                name,
                setName,
                personalityResult,
                setPersonalityResult
            }}
        >
            {children}
        </DataContext.Provider>
    )
}